import React, { FC, useEffect, useState, useContext, Fragment, Suspense } from 'react';
import axios from 'axios';
import {
  Grid,
  Container,
  Typography,
  makeStyles,
  Button,
  TextField,
  CircularProgress,
  ButtonGroup,
  MenuItem,
  useMediaQuery,
  useScrollTrigger,
  Tab,
  Tabs,
  Theme,
  IconButton,
  Paper,
  InputBase,
  Divider,
  useTheme,
  Zoom,
  ListItemText,
  List,
  ListItem
} from '@material-ui/core';
import { AddBox, CloseRounded, SearchRounded } from '@material-ui/icons';
import { Page, StandardConfirmationDialog, PaperCustom, Breadcrumb, CardCustomer } from 'components';
import {
  PRODUCT_BASE_URL,
  CATEGORY_BASE_URL,
  PARTNER_BASE_URL,
  PRODUCT_SEARCH,
  USER_BASE_URL,
  GET_SALES_ORDER_NUMBER_BASE_URL,
  INVOICE_ITEM_URL,
  PARTNER_DETAIL_BASE_URL,
  SALES_ORDER_BASE_URL,
  GET_HISTORY_DISCOUNT
} from 'constants/url';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { CartContext } from 'contexts/CartContext';
import { dummyCategory, dummyZone, dummyPartner, dummyCartList, dummyUser, dummyProduct } from 'utils/dummy';
import { CurrentUserContext } from 'contexts/CurrentUserContext';
import useRouter from 'hooks/useRouter';
import useDebounce from 'hooks/useDebounce';
import ProductModal from './components/ProductModal';
import ProductPackage from './components/ProductPackage';
import { format } from 'date-fns';
import Alert from '@material-ui/lab/Alert';
import { WHITE, BLUE_PRIMARY, ORANGE } from 'constants/colors';
import useRole from 'hooks/useRole';
import { Autocomplete, AutocompleteInputChangeReason, Pagination } from '@material-ui/lab';
import ProductReview from './components/ProductReview';
import Search from '@material-ui/icons/Search';
import ModalDiscount from './components/ModalDiscount';
import { Add, ArrowForward, ArrowUpward } from '@material-ui/icons';
import InfiniteScroll from 'react-infinite-scroll-component';
import ImageModal from 'components/ImageModal';
import TypeUser from 'typings/enum/TypeUser';
import ModalCatalog from './components/ModalCatalog ';
import Loading from 'components/Loading';
import clsx from 'clsx';
import PaginationCustom from 'components/PaginationCustom';

const CardItem = React.lazy(() => import('./components/CardItem'));

type typeDiscount = {
  discountUnit: number;
  orderDate: string;
  price: number;
};
const useStyles = makeStyles((theme: Theme) => ({
  fourthGrid: {
    marginTop: 25
  },
  space: {
    paddingRight: 5,
    paddingTop: 5,
    marginTop: 5
  },

  button: {
    background: WHITE,
    color: BLUE_PRIMARY
  },
  paperTab: {
    padding: 8
  },

  clearCart: {
    backgroundColor: ORANGE,
    color: WHITE,
    marginTop: 10
  },
  productPriview: {
    marginTop: theme.spacing(2),

    marginBottom: theme.spacing(2),
    padding: '1em 0.5em',
    border: '1px solid rgba(0, 0, 0, 0.08)',
    borderRadius: '0.8em',
    minHeight: '20vh',
    transition: 'transform 10s ease-in',
    '&:hover': {
      boxShadow: '0 3px 3px 0 rgba(0, 0, 0, 0.007), 0 4px 7px 0 #BDE9FA',
      transform: 'scale(1,1)'
    }
  },

  tabPanel: {
    [theme.breakpoints.down('md')]: {
      flexGrow: 1,
      display: 'flex',
      justifyContent: 'center'
    }
  },
  containerCenter: {
    [theme.breakpoints.down('md')]: {
      display: 'flex',

      flexDirection: 'column-reverse',
      justifyContent: 'center'
    }
  },
  loadingWrapper: {
    height: 0,
    overflow: 'hidden',
    marginTop: '1.5em',
    transition: 'all .3s ease-in-out'
  },
  loadingWrapperActive: {
    height: '30px',
    overflow: 'auto'
  },
  search: {
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      width: '100%'
    },
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'flex-end'
    }
  },
  containerSugestSearch: {
    position: 'absolute',
    backgroundColor: '#fff',

    overflowY: 'scroll',
    borderRadius: '0 0 0.5em 0.5em',
    border: '1px solid rgba(0, 0, 0, 0.08)',
    top: 50,
    zIndex: 2,
    [theme.breakpoints.down('md')]: {
      width: '100%',
      maxHeight: '40vh'
    },
    [theme.breakpoints.up('md')]: {
      width: '98%',
      maxHeight: '30vh'
    }
  },

  textContentMobile: {
    fontSize: '0.875rem'
  },
  textContentBoldMobile: {
    fontSize: '0.875rem',
    fontWeight: 500
  },
  CardContainer: {
    margin: '0.6em 0'
  },
  searchingContainer: {
    marginTop: theme.spacing(1),
    padding: '0px 10px',
    display: 'flex',
    alignItems: 'center',

    border: '1px solid rgba(0, 0, 0, 0.08)',
    borderRadius: '5px',
    [theme.breakpoints.up('md')]: {
      flexGrow: 0.97
    }
  },
  sectionProduct: {
    marginTop: theme.spacing(1),
    maxHeight: '100vh',
    overflowX: 'scroll',
    borderTop: '1px solid rgba(0, 0, 0, 0.08)',
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  sectionProductMobile: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignContent: 'center'
  },

  spacing: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  paginationContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },

  buttonBottom: {
    [theme.breakpoints.down('md')]: {
      position: 'fixed',
      zIndex: 1,
      bottom: 0,
      right: 0,
      backgroundColor: WHITE,
      borderTop: '1px solid rgba(0, 0, 0, 0.08)',
      left: 0,
      padding: '0.6em 0'
    }
  },
  rootContainer: {
    [theme.breakpoints.down('xs')]: {
      padding: 0,
      marginTop: '-2em'
    }
  },
  hideMobile: {
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  upButton: {
    position: 'fixed',
    zIndex: 1,
    bottom: 60,
    right: 16,
    backgroundColor: WHITE,
    borderRadius: '2rem',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  category: {
    border: 0,
    backgroundColor: '#FFF'
  },
  upButtonArrow: {
    border: '1px solid rgb(0, 0, 0, 0.12)'
  },
  centerFilter: {
    [theme.breakpoints.down('xs')]: {
      marginTop: '0.3em'
    }
  },
  containerFilter: {
    gap: '1em',
    display: 'flex',
    flexWrap: 'wrap',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'space-between',
      margin: '0.5em 0'
    },
    [theme.breakpoints.up('md')]: {
      margin: '1em 0'
    }
  },
  containerFilterText: {
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer',
    padding: '0.6em',
    borderRadius: '6px',
    boxShadow: '0 2px 5px rgb(0 0 0 / 14%)',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    textTransform: 'uppercase',
    [theme.breakpoints.down('md')]: {
      flex: '40%'
    }
  }
}));

const ProductPage: FC = () => {
  const classes = useStyles();
  const { history, location } = useRouter();
  const theme = useTheme();
  const { cart, setCart } = useContext(CartContext);

  const [open, setOpen] = useState<boolean>(false);
  const [openPackage, setOpenPackage] = useState<boolean>(false);

  const [selectedId, setSelectedId] = useState<number>();
  const [confirmationDelete, setConfirmationDelete] = useState<boolean>(false);
  const [snackbarVariant, setSnackbarVariant] = useState<'success' | 'error'>('success');
  const [message, setMessage] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [openSnackbarProduct, setOpenSnackbarProduct] = useState<boolean>(false);
  const [value, setValue] = useState<number>(0);
  const { currentUser } = useContext(CurrentUserContext);
  const [expandHistory, setExpandHistory] = useState<boolean>(false);
  const [isDownload, setDownload] = useState<boolean>(false);
  const params: any = location.state;
  const [tab, setTab] = React.useState<number>(0);
  const [isAddItem, setIsAddItem] = useState<boolean>(false);
  const [hasPlafon, setHasPlafon] = useState<boolean>(false);
  const [loadCategory, setLoadCategory] = useState<boolean>(false);
  const [isLoadingCategories, setIsLoadingCategories] = useState<boolean>(false);
  const showComponent = useMediaQuery(theme.breakpoints.down('md'));
  const [orderNumber, setOrderNumber] = useState<string>('');
  const [orderDate, setOrderDate] = useState<Date | null>(new Date());
  const [orderDateMessage, setOrderDateMessage] = useState<string>('');
  const [idClick, setIdClick] = useState<number>(0);
  const [idClickDiscount, setIdClickDiscount] = useState<number>(0);
  const [openImage, setOpenImage] = useState<boolean>(false);
  const [imagePath, setImagePath] = useState<string>('');
  const [partner, setPartner] = useState<PartnerModel>(dummyPartner);
  const [partners, setPartners] = useState<PartnerModel[]>([dummyPartner]);
  const [partnerMessage, setPartnerMessage] = useState<string>('');
  const [loadPartner, setLoadPartner] = useState<boolean>(false);
  const [currentSortProduct, setCurrentSortProduct] = useState<string>('id-desc');
  const [currentSortPackage, setCurrentSortPackage] = useState<string>('id-desc');
  const [currentPage, setCurrentPage] = useState<number>(-1);
  const [currentPagePackage, setCurrentPagePackage] = useState<number>(1);
  const [currentPageProduct, setCurrentPageProduct] = useState<number>(1);
  const [currentPageSearch, setCurrentPageSearch] = useState<number>(1);
  const [zone, setZone] = useState<ZoneModel>(dummyZone);
  const [zoneMessage, setZoneMessage] = useState<string>('');

  const [sort, setSort] = useState<string>('');
  const [id, setId] = useState<number>(0);
  const [sales, setSales] = useState<UserDetailsModel>(dummyUser);
  const [saleses, setSaleses] = useState<UserDetailsModel[]>([dummyUser]);
  const [categoryDownload, setCategoryDownload] = useState<string>('');
  const [salesMessage, setSalesMessage] = useState<string>('');
  const [loadSales, setLoadSales] = useState<boolean>(false);
  const [openEdit, setOpenEdit] = useState<boolean>(false);

  const [salesOrderItem, setSalesOrderItem] = useState<SalesOrderItemModel[]>([]);
  const [salesOrderItemMessage, setSalesOrderItemMessage] = useState<string>('');
  const [categories, setCategories] = useState<CategoryModel[]>([dummyCategory]);
  const [categoriesDownload, setCategoriesDownload] = useState<CategoryModel[]>([]);
  const [qtyEdit, setQtyEdit] = useState<number>(0);
  const [discount, setDiscount] = useState<number>(0);
  const [price, setPrice] = useState<number>(0);
  const [subTotalPrice, setSubTotalPrice] = useState<number>(0);
  const [totalDiscount, setTotalDisCount] = useState<number>(0);
  const [totalPriceTmp, setTotalPriceTmp] = useState<number>(0);
  const [totalDiscountTmp, setTotalDiscountTmp] = useState<number>(0);
  const [notes, setNotes] = useState<string>('');
  const [term, setTerm] = useState<string>('');
  const [category, setCategory] = useState<CategoryModel>();
  const [isDeny, setDeny] = useState<boolean>(false);
  const [isSubmit, setSubmit] = useState<boolean>(false);
  const [isUpdate, setUpdate] = useState<boolean>(false);
  const [isUpdateData, setUpdateData] = useState<boolean>(false);
  const [updateSO, setUpdateSO] = useState<boolean>(false);
  const [loadUpdate, setLoadUpdate] = useState<boolean>(false);
  const [downloadCatalog, setDownloadCatalog] = useState<boolean>(false);

  const [productId, setProductId] = useState<number>(0);

  const [historyUser, setHistoryUser] = useState<HistoryModel[]>([]);
  const [orderNumberLoad, setOrderNumberLoad] = useState<boolean>(false);
  const [hasDebt, setHasDebt] = useState<boolean>(false);
  const [isPackage, setPackage] = useState<boolean>(false);
  const [latitude, setLatitude] = useState<number>(0);
  const [longitude, setLongitude] = useState<number>(0);
  const [products, setProducts] = useState<ProductModel[]>([dummyProduct]);
  const [productResource, setProductResource] = useState<ProductModel[]>([]);
  const [productSelectedItem, setProductSelectedItem] = useState<OrderItems[]>([]);
  const [productLoading, setProductLoading] = useState<boolean>(false);
  const [queryString, setQueryString] = useState<string>();
  const [isPreview, setIsPreview] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const [searchTemp, setSearchTemp] = useState('');
  const [keyWordResource, setKeyWordResource] = useState<{ productName: string }[]>([]);
  const [isKeyWord, setIsKeyWord] = useState<boolean>(false);
  const [keyWord, setKeyWord] = useState<string>('');
  const [filterKey, setFilterKey] = useState<number>(0);
  const [wareHouse, setWareHouse] = useState<string>('ALL');
  const [discountResource, setDiscountResource] = useState<typeDiscount[]>([]);

  const isAllowAction = useRole({
    type: (currentUser && currentUser.type) || TypeUser.SALES,
    allowed: [TypeUser.SUPERADMIN, TypeUser.ADMIN]
  });

  const isCanCreate = useRole({
    type: (currentUser && currentUser.type) || TypeUser.SALES,
    allowed: [TypeUser.SUPERADMIN, TypeUser.ADMIN, TypeUser.BUYER]
  });
  const isSalesorSpv = useRole({
    type: (currentUser && currentUser.type) || TypeUser.SALES,
    allowed: [TypeUser.SALES, TypeUser.SUPERVISOR]
  });
  const isSales = useRole({
    type: (currentUser && currentUser.type) || TypeUser.SALES,
    allowed: [TypeUser.SALES]
  });

  const isAllowCreateSO = useRole({
    type: (currentUser && currentUser.type) || TypeUser.SALES,
    allowed: [TypeUser.BUYER, TypeUser.ACCOUNTING]
  });

  const fetchProductPaganation = async (signal?: AbortSignal) => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const params = new URLSearchParams();
    params.append('route', (currentUser && currentUser.SalesRoute ? currentUser.SalesRoute.map(value => value.ZoneId) : []).join(','));
    if (search) {
      params.append('keyword', search);
    }

    if (category && category.id > 0) {
      params.append('CategoryId', String(category.id));
    }

    const [orderby, order] = (isPackage ? currentSortPackage : currentSortProduct).split('-');
    params.append('orderBy', orderby);

    params.append('isProductPackage', String(isPackage));

    if (queryString === '') {
      params.append('page', String(isPackage ? currentPagePackage : currentPageProduct));
    } else {
      params.append('page', String(currentPageSearch));
    }

    if (currentUser && isAllowAction) {
      if (wareHouse !== 'ALL') {
        params.append('WareHouseId', currentUser && currentUser.WareHouseName.filter(value => value.name === wareHouse)[0].id.toString());
      }
    }

    if (filterKey === 0) {
      params.append('ordered', 'desc');
    }
    if (filterKey === 2) {
      params.append('populerItem', 'true');
    }
    if (filterKey === 1) {
      params.append('ordered', 'desc');
    }

    if (filterKey === 3) {
      params.append('ordered', 'desc');
      params.append('orderBy', 'restock');
    }

    if (filterKey === 4) {
      params.append('hardSelling', 'true');
    }
    if (filterKey === 5) {
      params.append('draft', 'true');
    }

    if (zone && zone.id > 0 && isPackage) {
      params.append('ZoneId', zone.id.toString());
    }
    if (isSales) {
      params.append('isActive', 'true');
    }
    setSort(isPackage ? currentSortPackage : currentSortProduct);

    const { data } = await axios.get<{ data: ProductModel[]; meta: MetaData }>(`${PRODUCT_BASE_URL}?${params}`, { cancelToken: source.token });
    // Cancel the request if TanStack Query signals to abort
    signal?.addEventListener('abort', () => {
      source.cancel('Query was cancelled by TanStack Query');
    });

    return data;
  };

  const fetchProductInfinite = async (page: string, signal?: AbortSignal) => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const params = new URLSearchParams();
    if (search) {
      params.append('keyword', search);
    }
    if (category && category.id > 0) {
      params.append('CategoryId', String(category.id));
    }

    const [orderby, order] = (isPackage ? currentSortPackage : currentSortProduct).split('-');

    params.append('isProductPackage', String(isPackage));

    if (currentUser && isAllowAction) {
      if (wareHouse !== 'ALL') {
        params.append('WareHouseId', currentUser && currentUser.WareHouseName.filter(value => value.name === wareHouse)[0].id.toString());
      }
    }

    if (filterKey === 0) {
      params.append('ordered', 'desc');
    }
    if (filterKey === 2) {
      params.append('populerItem', 'true');
    }
    if (filterKey === 1) {
      params.append('ordered', 'desc');
    }

    if (filterKey === 3) {
      params.append('ordered', 'desc');
      params.append('orderBy', 'restock');
    } else {
      params.append('orderBy', orderby);
    }

    if (filterKey === 4) {
      params.append('hardSelling', 'true');
    }
    if (filterKey === 5) {
      params.append('draft', 'true');
    }
    if (isSales) {
      params.append('isActive', 'true');
    }
    params.append('page', page);

    const { data } = await axios.get<{ nextPage: number | undefined; data: ProductModel[]; meta: MetaData }>(`${PRODUCT_BASE_URL}?${params}`, {
      cancelToken: source.token
    });
    // Cancel the request if TanStack Query signals to abort
    signal?.addEventListener('abort', () => {
      source.cancel('Query was cancelled by TanStack Query');
    });

    const hasNext = Number(data.meta.current_page) + 1 <= +data.meta.last_page;
    return {
      nextPage: hasNext ? Number(data.meta.current_page) + 1 : undefined,
      data: data.data,
      meta: data.meta
    };
  };

  const { status, error, data, isFetching } = useQuery({
    enabled: !showComponent,
    queryKey: [
      'products',
      {
        filterKey,
        wareHouse,
        queryString,
        category,
        currentPageProduct,
        currentPagePackage,
        isPackage,
        currentSortProduct,
        currentSortPackage,
        zone,
        currentPageSearch,
        search,
        isSales
      }
    ],
    queryFn: ({ signal }) => fetchProductPaganation(signal),
    keepPreviousData: true
  });

  const { status: statusInfinite, data: dataInfinite, error: errorInfinite, hasNextPage, isFetchingNextPage, fetchNextPage } = useInfiniteQuery<{
    nextPage: number | undefined;
    data: ProductModel[];
    meta: MetaData;
  }>({
    enabled: showComponent,
    queryKey: [
      'products',
      'infinite',
      {
        filterKey,
        wareHouse,
        queryString,
        category,
        currentPageProduct,
        currentPagePackage,
        isPackage,
        currentSortProduct,
        currentSortPackage,
        zone,
        currentPageSearch,
        search,
        isSales
      }
    ],
    getNextPageParam: prevData => prevData.nextPage,
    queryFn: ({ pageParam = 1 }) => fetchProductInfinite(pageParam)
  });

  const dataProduct = dataInfinite && dataInfinite.pages.flatMap(product => product.data);

  console.log('dataProduct -->', dataProduct);

  const handleCloseConfirmationDelete = () => {
    setConfirmationDelete(false);
  };

  useEffect(() => {
    if (currentUser) {
      const isAccess: string[] = [TypeUser.ADMIN, TypeUser.SUPERADMIN];
      if (isAccess.includes(currentUser.type)) {
        setFilterKey(0);
      } else {
        setFilterKey(1);
      }
    }
  }, [currentUser]);

  const deleteProduct = async () => {
    try {
      await axios.delete(`${PRODUCT_BASE_URL}/${selectedId}`);
      setProducts(products.filter(value => value.id !== selectedId));
    } catch (err) {
      console.log(err);
    }
    setConfirmationDelete(false);
  };

  const handleCloseDownload = () => {
    setDownloadCatalog(false);
    setDownload(false);
    setCategoryDownload('');
  };

  const handleConfirmSnackbar = (): void => {
    setOpenSnackbar(false);
    history.push(`/penjualan`);
  };

  const setHardSelling = async (id: number, hardSelling: boolean) => {
    const data = {
      id,
      hardSelling: hardSelling ? false : true
    };
    try {
      await axios.post(`${PRODUCT_BASE_URL}/hardSelling`, data);
      const index = productResource.findIndex(val => val.id === id);
      const tempData = [...productResource];
      tempData[index].hardSelling = hardSelling ? false : true;
      setProductResource(tempData);
    } catch (error) {
      console.log(error);
    }
  };

  const setPopularItem = async (id: number, populerItem: boolean) => {
    const data = {
      id,
      populerItem: populerItem ? false : true
    };
    try {
      await axios.post(`${PRODUCT_BASE_URL}/populer`, data);
      const index = productResource.findIndex(val => val.id === id);
      const tempData = [...productResource];
      tempData[index].populerItem = populerItem ? false : true;
      setProductResource(tempData);
    } catch (error) {
      console.log(error);
    }
  };

  const handleConfirmSnackbarProduct = (): void => {
    setOpen(false);
    setQueryString('');
    setOpenPackage(false);
    setOpenSnackbar(false);
    setOpenSnackbarProduct(false);
    history.push(`/produk`);
  };

  const handleOpenForm = (): void => {
    setOpen(true);
  };

  const handleOpenPackage = (): void => {
    setOpenPackage(true);
  };

  const handleDownload = async () => {
    setDownload(true);
    const params = new URLSearchParams();
    const getQueryParams = () => {
      params.append('CategoryId', categoriesDownload.filter(val => val.name === categoryDownload)[0].id.toString());
      return params;
    };

    try {
      const { data } = await axios.get(`${PRODUCT_BASE_URL}/download?${getQueryParams()}`, { responseType: 'blob' });
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'katalog.pdf');
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.log('error :', error);
    } finally {
      setDownload(false);
    }
  };

  const trigger = useScrollTrigger({
    target: window,
    disableHysteresis: true,
    threshold: 600
  });
  const handleAddItem = (items: OrderItems): void => {
    setIsAddItem(true);
    setProductSelectedItem([...productSelectedItem, items]);
  };

  const handleDeleteItem = (id: number): void => {
    if (!productSelectedItem.some(value => value.ProductId === id)) return;
    const dataResource = productSelectedItem.filter(value => value.ProductId !== id);
    setProductSelectedItem(dataResource);
  };
  const handleChangeNote = (id: number, note: string) => {
    setProductSelectedItem(prev => {
      return prev.map(item => {
        if (item.ProductId === id) {
          return { ...item, note };
        } else {
          return item;
        }
      });
    });
  };

  const updateListItem = (id: number, total: number) => {
    if (productSelectedItem.some(val => val.ProductId === id)) {
      const index = productSelectedItem.findIndex(val => val.ProductId === id);
      const newList = [...productSelectedItem];
      newList[index].totalItem = total;
      setProductSelectedItem(newList);
    }
  };

  const handleTabs = (event: React.ChangeEvent<{}>, newValue: number): void => {
    setTab(newValue);

    setPackage(newValue === 1);
  };

  const handleOpenImage = (imagePath: string) => {
    setOpenImage(true);
    setImagePath(imagePath);
  };
  const handleCloseImage = () => {
    setOpenImage(false);
  };

  const handleChangeCategory = (category: CategoryModel) => {
    setCurrentPageSearch(1);
    setCurrentPagePackage(1);
    setCurrentPageProduct(1);
    if (isAllowAction) {
      setFilterKey(0);
    } else {
      setFilterKey(1);
    }
    setCategory(category);
  };

  const handleSearchPartner = async (value: string) => {
    const params = new URLSearchParams();
    params.append('keyword', value);
    params.append('partnerType', 'CUSTOMER');
    params.append('Zone', (currentUser && currentUser.SalesRoute && currentUser.SalesRoute.map(value => value.ZoneId).join(',')) || '');

    setLoadPartner(true);
    setPartnerMessage('');
    setHasPlafon(false);
    setDeny(false);
    try {
      const { data } = await axios.get(`${PARTNER_BASE_URL}?${params.toString()}`);
      setPartners(data.data);
      setProductSelectedItem([]);
    } catch (error) {
      console.log('error :', error);
    } finally {
      setLoadPartner(false);
    }
  };

  const handleProductStatus = async (id: number, isActive: boolean) => {
    const data = {
      id,
      isActive
    };
    try {
      await axios.post(`${PRODUCT_BASE_URL}/isActive`, data);
      const index = productResource.findIndex(val => val.id === id);
      const tempData = [...productResource];
      tempData[index].isActive = isActive;
      setProductResource(tempData);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearchSales = async (value: string) => {
    const params = new URLSearchParams();
    params.append('keyword', value);
    params.append('isActive', 'true');
    if (isAllowAction && zone.id !== 0) {
      params.append('ZoneId', String(zone.id));
    }
    setLoadSales(true);
    try {
      const { data } = await axios.get(`${USER_BASE_URL}?${params.toString()}`);
      setSaleses(data.data);
    } catch (error) {
      console.log('error :', error);
    } finally {
      setLoadSales(false);
    }
  };

  const handleOrderNumber = async () => {
    setOrderNumberLoad(true);
    try {
      const { data } = await axios.get(GET_SALES_ORDER_NUMBER_BASE_URL);
      setOrderNumber(data.data.orderNumber);
    } catch (error) {
      console.log('error :', error);
    } finally {
      setOrderNumberLoad(false);
    }
  };

  const handleSnackBar = (open: boolean, variant: 'success' | 'error', message: string): void => {
    setSnackbarVariant(variant);
    setOpenSnackbar(open);
    setMessage(message);
  };

  const handleSnackBarProduct = (open: boolean, variant: 'success' | 'error', message: string): void => {
    setSnackbarVariant(variant);
    setOpenSnackbarProduct(open);
    setMessage(message);
  };

  const handleCloseSnackbar = (): void => {
    setOpenSnackbar(false);
    setOpenSnackbarProduct(false);
  };

  const validation = (): boolean => {
    let valid = true;

    if (!partner) {
      setPartnerMessage('Nama Mitra tidak boleh kosong.');
      valid = false;
    }

    if (!sales || sales.id === 0) {
      setSalesMessage('Nama Sales tidak boleh kosong');
      valid = false;
    }

    if (!orderDate) {
      setOrderDateMessage('Tanggal Order tidak boleh kosong.');
      valid = false;
    }

    if (!zone || zone.id === 0) {
      setZoneMessage('Route tidak boleh kosong');
      valid = false;
    }

    if (salesOrderItem.length === 0 || !salesOrderItem) {
      setSalesOrderItemMessage('Item tidak boleh kosong.');
    }
    return valid;
  };

  const resetValidation = () => {
    setPartnerMessage('');
    setHasPlafon(false);
    setSalesMessage('');
    setOrderDateMessage('');
    setZoneMessage('');
    setSalesOrderItemMessage('');
  };

  const getHistory = async () => {
    const params = new URLSearchParams();
    params.append('PartnerId', String(partner.id));
    params.append('ProductId', String(productId));
    params.append('limit', '3');
    try {
      const { data } = await axios.get(`${INVOICE_ITEM_URL}?${params.toString()}`);
      if (data.data.length > 0) {
        setHistoryUser(
          // eslint-disable-next-line
          data.data.map((value: any) => {
            return {
              date: value.createdAt,
              price: value.price
            };
          })
        );
      }
    } catch (error) {
      console.log('error :', error);
    }
  };

  const handleCustomer = async () => {
    setLoadPartner(true);
    setLoadUpdate(true);
    setPartnerMessage('');
    setDeny(false);
    try {
      const { data } = await axios.get(PARTNER_DETAIL_BASE_URL(cart ? cart.UserId : 0));
      setPartner(data.data);
      setPartners([data.data]);
    } catch (error) {
      console.log('error :', error);
    } finally {
      setLoadPartner(false);
      setLoadUpdate(false);
    }
  };

  const handleSales = async () => {
    setLoadSales(true);
    try {
      const { data } = await axios.get(`${USER_BASE_URL}/${currentUser && currentUser.id}`);
      setSaleses([data.data]);
      setSales(data.data);
    } catch (error) {
      console.log('error :', error);
    } finally {
      setLoadSales(false);
    }
  };

  const handleCancelSearch = () => {
    setSearchTemp('');

    setSearch('');
    setKeyWord('');
    setIsKeyWord(false);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      setSearch(searchTemp);
      setIsKeyWord(false);
      setCurrentPageSearch(1);
      setCurrentPagePackage(1);
      setCurrentPageProduct(1);
      if (isAllowAction) {
        setFilterKey(0);
      } else {
        setFilterKey(1);
      }
    }
  };

  const handleSearchProduct = () => {
    setSearch(searchTemp);
    setIsKeyWord(false);
    setCurrentPageSearch(1);
    setCurrentPagePackage(1);
    setCurrentPageProduct(1);
    if (isAllowAction) {
      setFilterKey(0);
    } else {
      setFilterKey(1);
    }
  };

  const debouncedSearchTerm = useDebounce(keyWord, 500);

  useEffect(() => {
    if (debouncedSearchTerm.length >= 3) {
      dispatchProductSearch();
    }
  }, [debouncedSearchTerm]);

  const dispatchProductSearch = async () => {
    const getQueryParams = () => {
      const params = new URLSearchParams();
      if (keyWord) {
        params.append('keyword', keyWord);
      }
      if (isSales) {
        params.append('isActive', 'true');
      }
      if (isPackage) {
        params.append('isproductpackage', String(isPackage));
      }

      return params;
    };
    try {
      const { data } = await axios.get(`${PRODUCT_SEARCH}?${getQueryParams()}`);

      data.data.length > 0 && setIsKeyWord(true);

      setKeyWordResource(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async () => {
    resetValidation();
    setSubmit(true);

    if (!validation()) {
      setSubmit(false);
      return;
    }
    const getItems = productSelectedItem.map((value: OrderItems) => ({
      ProductId: value.ProductId,
      typeUnit: value.typeUnit,
      totalItem: value.totalItem,
      price: value.price,
      upPrice: value.upPrice,
      discountUnit: value.discountUnit,
      notes: value.note
    }));

    try {
      const { data } = await axios.post(SALES_ORDER_BASE_URL, {
        id,
        PartnerId: partner && partner.id,
        SalesId: sales && sales.id,
        orderDate,
        items: getItems,
        latitude,
        isPlafon: hasPlafon,
        longitude
      });

      handleSnackBar(true, 'success', 'Orderan berhasil dibuat');
    } catch (error) {
      console.log('error :', error);
      handleSnackBar(true, 'error', 'Orderan gagal dibuat');
    } finally {
      setSubmit(false);
    }
  };
  const handlePriview = async () => {
    setIsPreview(true);
  };

  const handleClickEdit = (id: number) => {
    setOpenEdit(true);
    setIdClick(id);
  };

  const handleShowHistory = (id: number) => {
    setIdClickDiscount(id);
  };

  const handleDelete = (id: number) => {
    const newItem = productSelectedItem.filter(value => {
      return value.ProductId !== id;
    });
    setProductSelectedItem(newItem);
    if (newItem.length > 0) return;
    setIsPreview(false);
  };

  const handleEditItem = (): void => {
    if (productSelectedItem.some(val => val.ProductId === idClick)) {
      const index = productSelectedItem.findIndex(val => val.ProductId === idClick);
      const newList = [...productSelectedItem];
      newList[index].totalItem = qtyEdit;
      newList[index].discountUnit = discount;
      newList[index].upPrice = price > newList[index].price;
      newList[index].price = price;
      setProductSelectedItem(newList);
      setOpenEdit(false);
    }
  };

  useEffect(() => {
    setPartnerMessage('');
    setDeny(false);

    if (productSelectedItem.length === 0) {
      return;
    }

    const totalPrice = productSelectedItem.map(value => value.price * value.totalItem).reduce((a, b) => a + b, 0);
    setTotalPriceTmp(totalPrice);
    setSubTotalPrice(salesOrderItem.map(value => (value.isDeleted ? 0 : value.subTotalPrice)).reduce((a, b) => a + b));
    setTotalDiscountTmp(salesOrderItem.map(value => (value.isDeleted ? 0 : value.discount)).reduce((a, b) => a + b));

    if (partner.id !== 0) {
      const totalBill = partner.totalBill && partner.totalBill > 0 ? totalPrice + partner.totalBill : 0;
      const plafon = partner.plafon || 0;

      if (plafon > 0 && totalPrice > plafon) {
        setHasPlafon(true);
      }

      if (totalBill > plafon && plafon > 0) {
        setPartnerMessage('Customer ini memiliki jumlah total tagihan belum terbayarkan.');
        setDeny(true);
      }
      setHasDebt(partner.hasDebt || false);
    }
  }, [productSelectedItem, isUpdate]);

  useEffect(() => {
    getHistory();
  }, [productId]);

  useEffect(() => {
    handleOrderNumber();
    handleSales();
  }, []);

  useEffect(() => {
    if (partner.id < 1) return;

    setZone(partner.Zone || dummyZone);
    setHasDebt(partner.hasDebt || false);

    const totalBill = partner.totalBill && partner.totalBill > 0 ? partner.totalBill : 0;
    const plafon = partner.plafon || 0;

    if (totalBill > plafon && plafon > 0) {
      setPartnerMessage('Customer ini memiliki jumlah total tagihan belum terbayarkan.');
      setHasPlafon(true);
      setDeny(true);
    }

    setLoadPartner(false);
  }, [partner]);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      position => {
        console.log('Latitude is :', position.coords.latitude);
        console.log('Longitude is :', position.coords.longitude);

        setLatitude(position.coords.latitude);
        setLongitude(position.coords.longitude);
      },
      error => {
        console.log('error location: ', error);
      }
    );
  }, []);

  const fetchHistoryDiscount = async () => {
    try {
      const { data } = await axios.get(GET_HISTORY_DISCOUNT(partner && partner.id, idClickDiscount));
      setDiscountResource(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchHistoryDiscount();
  }, [expandHistory]);

  const handleBackTop = (event: React.MouseEvent<HTMLDivElement>) => {
    const anchor = ((event.target as HTMLDivElement).ownerDocument || document).querySelector('#back-to-top');

    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };
  const isAllowed = useRole({
    type: (currentUser && currentUser.type) || 'SALES',
    allowed: [TypeUser.SALES, TypeUser.SUPERVISOR, TypeUser.ACCOUNTING]
  });

  const handleSearchCategory = async (value: string) => {
    const params = new URLSearchParams();
    params.append('product', 'true');
    params.append('keyword', value);
    setLoadCategory(true);
    try {
      const { data } = await axios.get(`${CATEGORY_BASE_URL}?${params.toString()}`);
      setCategories(data.data);
      setLoadCategory(false);
    } catch (error) {
      console.log('error :', error);
      setLoadCategory(false);
    }
  };

  const fetchCategory = async () => {
    setIsLoadingCategories(true);
    try {
      const { data } = await axios.get(`${CATEGORY_BASE_URL}?product=true`);
      setCategoriesDownload(data.data);
      setIsLoadingCategories(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchCategory();
  }, []);

  const getPrice = (id: number) => {
    const price = data?.data
      .filter(value => value.id === id)
      .map(value => value.ProductPrice && value.ProductPrice.filter(value => value.ZoneId === zone.id).map(value => value.price));

    return Number(price);
  };

  return (
    <Page title='Produk'>
      <Container className={classes.rootContainer}>
        <Grid container direction='row' spacing={1}>
          <Grid container direction='row' className={classes.hideMobile}>
            <Typography variant='h1' component='h1'>
              Produk
            </Typography>
          </Grid>

          <Grid item lg={6} md={6} sm={12} xs={12} style={{ margin: showComponent ? '1.2em 0 0 1.2em' : 0 }}>
            <Breadcrumb />
          </Grid>

          <Grid container direction='row' item lg={6} md={6} sm={12} xs={12} spacing={1} justify='flex-end'>
            {isCanCreate && (
              <ButtonGroup variant='contained'>
                <Button color='inherit' className={classes.button} onClick={handleOpenPackage}>
                  <AddBox fontSize='small' /> &nbsp; Tambah Paket
                </Button>

                <Button color='primary' onClick={handleOpenForm}>
                  <AddBox fontSize='small' /> &nbsp; Tambah Produk
                </Button>
                {!isAllowCreateSO && <Button onClick={() => setDownloadCatalog(true)}>Download PDF</Button>}
              </ButtonGroup>
            )}

            {isSalesorSpv && <Button onClick={() => setDownloadCatalog(true)}>Download PDF</Button>}
          </Grid>
        </Grid>

        <PaperCustom>
          <ImageModal open={openImage} handleClose={handleCloseImage} imageUrl={imagePath} />
          <Grid container direction='row' spacing={1}>
            {!isAllowCreateSO && (
              <>
                <Grid item xl={6} lg={6} sm={12} xs={12} md={6}>
                  <TextField
                    id='orderNumber'
                    required
                    fullWidth
                    label='No Order'
                    placeholder='No Order Penjualan'
                    value={orderNumber}
                    onChange={e => setOrderNumber(e.target.value)}
                    InputProps={{
                      endAdornment: (loadUpdate || orderNumberLoad) && <CircularProgress color='inherit' size={20} />
                    }}
                    disabled
                  />
                </Grid>
                <Grid item xl={6} lg={6} sm={12} xs={12} md={6}>
                  <TextField
                    id='orderDate'
                    required
                    fullWidth
                    type='date'
                    disabled
                    label='Tanggal Order'
                    placeholder='Tanggal Terbit Order Penjualan'
                    value={orderDate && format(orderDate, 'yyyy-MM-dd')}
                    onChange={e => setOrderDate(new Date(e.target.value))}
                    error={orderDateMessage !== ''}
                    helperText={orderDateMessage}
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>
                <Grid item xl={6} lg={6} sm={12} xs={12} md={6}>
                  <Autocomplete
                    id='partner'
                    value={partner}
                    options={partners}
                    getOptionLabel={option => option.name}
                    getOptionSelected={(option, value) => {
                      return value.id === option.id;
                    }}
                    onChange={(event: any, value: any) => {
                      if (value) {
                        setPartner(value);
                      }
                    }}
                    onOpen={e => handleSearchPartner('')}
                    onInputChange={(event: React.ChangeEvent<{}>, value: string, reason: AutocompleteInputChangeReason) => {
                      if (reason === 'clear') {
                        setPartner(dummyPartner);
                        setPartners([dummyPartner]);
                        setHasDebt(false);
                      }
                    }}
                    loading={loadPartner}
                    renderOption={(option, state) => (
                      <CardCustomer partner={option} isLoading={loadPartner} isSelected={option.id === partner.id && !loadPartner} />
                    )}
                    renderInput={params => (
                      <TextField
                        {...params}
                        required
                        label='Pilih Customer'
                        onChange={e => handleSearchPartner(e.target.value)}
                        error={partnerMessage !== ''}
                        helperText={partnerMessage}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <Fragment>
                              {loadPartner && <CircularProgress color='inherit' size={20} />}
                              {params.InputProps.endAdornment}
                            </Fragment>
                          )
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xl={6} lg={6} sm={12} xs={12} md={6}>
                  <TextField
                    id='route'
                    disabled
                    required
                    fullWidth
                    label='Route'
                    value={zone.name}
                    error={zoneMessage !== ''}
                    helperText={zoneMessage}
                    InputProps={{
                      endAdornment: loadUpdate && <CircularProgress color='inherit' size={20} />
                    }}
                  />
                </Grid>

                <Grid item xl={6} lg={6} sm={12} xs={12} md={6}>
                  <Autocomplete
                    id='sales'
                    value={sales}
                    options={saleses}
                    getOptionLabel={option => option.firstName + ' ' + option.lastName}
                    getOptionSelected={(option, value) => {
                      return value.id === option.id;
                    }}
                    onChange={(event: any, value: any) => {
                      if (value) {
                        setSales(value);
                        setLoadSales(false);
                      }
                    }}
                    onOpen={e => handleSearchSales('')}
                    loading={loadSales}
                    disabled={isAllowed}
                    renderInput={params => (
                      <TextField
                        {...params}
                        required
                        label='Pilih Sales'
                        onChange={e => handleSearchSales(e.target.value)}
                        error={salesMessage !== ''}
                        helperText={salesMessage}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <Fragment>
                              {loadSales && <CircularProgress color='inherit' size={20} />}
                              {params.InputProps.endAdornment}
                            </Fragment>
                          )
                        }}
                      />
                    )}
                  />
                </Grid>

                <Grid item xl={12} md={12} sm={12}>
                  {hasDebt && (
                    <Alert variant='filled' severity='warning'>
                      Mitra ini tidak bisa membuat SO, karena memiliki utang.
                    </Alert>
                  )}
                </Grid>
              </>
            )}

            {!isPreview ? (
              <Fragment>
                <Grid xs={12}>
                  <Typography variant='body2'>Semua Produk</Typography>

                  <Grid container className={classes.tabPanel}>
                    <Tabs value={tab} indicatorColor='primary' textColor='primary' onChange={handleTabs} aria-label='tab-produk'>
                      <Tab label='Semua Satuan' />
                      <Tab label='Produk Paketan' />
                    </Tabs>
                  </Grid>

                  <Grid xs={12} container alignItems='center' className={classes.containerCenter}>
                    <Grid xs={12} lg={5} xl={5} container>
                      <Grid
                        lg={isAllowAction ? 6 : 12}
                        xl={isAllowAction ? 6 : 12}
                        xs={isAllowAction ? 6 : 12}
                        sm={isAllowAction ? 6 : 12}
                        md={isAllowAction ? 6 : 12}
                        container
                        className={classes.centerFilter}
                      >
                        <Grid style={{ flex: isAllowAction ? 0.96 : 1 }}>
                          <Autocomplete
                            id='category'
                            fullWidth
                            options={categories}
                            getOptionLabel={option => option.name}
                            getOptionSelected={(option, value) => option.id === value.id}
                            onChange={(event: any, value: any) => value && handleChangeCategory(value)}
                            onOpen={() => handleSearchCategory('')}
                            onInputChange={(event: React.ChangeEvent<{}>, value: string, reason: AutocompleteInputChangeReason) => {
                              if (reason === 'clear') {
                                setCategory(dummyCategory);
                                setCategories([dummyCategory]);
                              }
                            }}
                            loading={loadCategory}
                            renderInput={params => (
                              <TextField
                                {...params}
                                fullWidth
                                label='Kategori'
                                onChange={e => handleSearchCategory(e.target.value)}
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <Fragment>
                                      {loadCategory && <CircularProgress color='inherit' size={20} />}
                                      {params.InputProps.endAdornment}
                                    </Fragment>
                                  )
                                }}
                                className={classes.category}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                      {isAllowAction && (
                        <Grid lg={6} xl={6} xs={6} sm={6} md={6} container justify='flex-end' className={classes.centerFilter}>
                          <Grid style={{ flex: 0.96 }}>
                            <TextField fullWidth label='Gudang' value={wareHouse} onChange={e => setWareHouse(e.target.value as string)} select>
                              <MenuItem value='ALL' key={0}>
                                Semua Gudang
                              </MenuItem>
                              {currentUser &&
                                currentUser.WareHouseName.map((val, index) => (
                                  <MenuItem value={val.name} key={index + 1}>
                                    {val.name}
                                  </MenuItem>
                                ))}
                            </TextField>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                    <Grid lg={7} xl={7} xs={12} md={12} sm={12} className={classes.search}>
                      <Paper className={classes.searchingContainer}>
                        <InputBase
                          classes={{
                            input: classes.textContentMobile
                          }}
                          style={{ flex: 1 }}
                          placeholder='Cari nama produk'
                          inputProps={{ 'aria-label': 'search' }}
                          value={searchTemp}
                          onKeyDown={handleKeyDown}
                          onChange={e => {
                            setSearchTemp(e.target.value);
                            setKeyWord(e.target.value);
                          }}
                        />
                        {searchTemp !== '' && (
                          <IconButton type='submit' aria-label='search' onClick={() => handleCancelSearch()}>
                            <CloseRounded fontSize='small' />
                          </IconButton>
                        )}
                        <IconButton type='submit' aria-label='search' onClick={() => handleSearchProduct()} disabled={searchTemp === ''}>
                          <Search fontSize='small' />
                        </IconButton>
                      </Paper>

                      {isKeyWord && keyWordResource.length > 0 && (
                        <Grid className={classes.containerSugestSearch}>
                          <List>
                            {keyWordResource.map((value, key) => (
                              <ListItem
                                button
                                key={key}
                                onClick={() => {
                                  handleSearchProduct();
                                  setIsKeyWord(false);
                                  setSearchTemp(value.productName);
                                  setSearch(value.productName);
                                }}
                              >
                                <SearchRounded style={{ paddingRight: '0.3em', color: 'grey' }} fontSize='default' />
                                <ListItemText primary={value.productName} />
                              </ListItem>
                            ))}
                          </List>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  <Grid xs={12} container className={classes.containerFilter}>
                    {isAllowAction && (
                      <Grid
                        className={classes.containerFilterText}
                        onClick={() => setFilterKey(0)}
                        style={{ border: `1px solid ${filterKey === 0 ? '#02A9EA' : 'rgba(0, 0, 0, 0.12)'}` }}
                      >
                        <Typography variant='h6' color={filterKey === 0 ? 'primary' : 'initial'}>
                          Semua Barang
                        </Typography>
                      </Grid>
                    )}
                    <Grid
                      className={classes.containerFilterText}
                      onClick={() => setFilterKey(1)}
                      style={{ border: `1px solid ${filterKey === 1 ? '#02A9EA' : 'rgba(0, 0, 0, 0.12)'}` }}
                    >
                      <Typography variant='h6' color={filterKey === 1 ? 'primary' : 'initial'}>
                        Terbaru
                      </Typography>
                    </Grid>
                    <Grid
                      className={classes.containerFilterText}
                      onClick={() => setFilterKey(2)}
                      style={{ border: `1px solid ${filterKey === 2 ? '#02A9EA' : 'rgba(0, 0, 0, 0.12)'}` }}
                    >
                      <Typography variant='h6' color={filterKey === 2 ? 'primary' : 'initial'}>
                        popular item
                      </Typography>
                    </Grid>
                    <Grid
                      className={classes.containerFilterText}
                      onClick={() => setFilterKey(3)}
                      style={{ border: `1px solid ${filterKey === 3 ? '#02A9EA' : 'rgba(0, 0, 0, 0.12)'}` }}
                    >
                      <Typography variant='h6' color={filterKey === 3 ? 'primary' : 'initial'}>
                        re-stock
                      </Typography>
                    </Grid>
                    <Grid
                      className={classes.containerFilterText}
                      onClick={() => setFilterKey(4)}
                      style={{ border: `1px solid ${filterKey === 4 ? '#02A9EA' : 'rgba(0, 0, 0, 0.12)'}` }}
                    >
                      <Typography variant='h6' color={filterKey === 4 ? 'primary' : 'initial'}>
                        push sale
                      </Typography>
                    </Grid>
                    {isAllowAction && (
                      <Grid
                        className={classes.containerFilterText}
                        onClick={() => setFilterKey(5)}
                        style={{ border: `1px solid ${filterKey === 5 ? '#02A9EA' : 'rgba(0, 0, 0, 0.12)'}` }}
                      >
                        <Typography variant='h6' color={filterKey === 5 ? 'primary' : 'initial'}>
                          MISC
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                  <Grid xs={12} className={classes.paginationContainer} container justify='space-between'>
                    <Typography>
                      {`Menampilkan ${data?.meta.total ?? 0} ${isPackage ? `paket` : `produk`} ${
                        category && category.id > 0 ? `untuk "${category.name}"` : ''
                      }  (${data?.meta.from ?? '0'} - ${data?.meta.to ?? '0'} dari ${data?.meta.total ?? 0})`}
                    </Typography>

                    <PaginationCustom
                      show={(data && data.data.length > 0) ?? false}
                      sxPagination={{
                        count: data?.meta.last_page,
                        boundaryCount: 2,
                        variant: 'outlined',
                        shape: 'rounded',
                        onChange: (event, page) => {
                          setCurrentPageSearch(page);
                        },
                        page: data?.meta.current_page
                      }}
                      customPageProps={{
                        defaultValue: currentPageSearch,
                        maxValue: data?.meta?.last_page ?? 0,
                        onSubmit(value) {
                          setCurrentPageSearch(value);
                        }
                      }}
                      sxPopover={{
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'right'
                        },
                        transformOrigin: {
                          vertical: 'top',
                          horizontal: 'right'
                        }
                      }}
                    />
                  </Grid>
                  <Grid xs={12} className={classes.sectionProduct} container>
                    <Grid
                      xs={12}
                      className={clsx(classes.loadingWrapper, isFetching && status !== 'loading' && classes.loadingWrapperActive)}
                      container
                      justify='center'
                    >
                      <Loading size='small' />
                    </Grid>
                    {status === 'loading' ? (
                      [0, 1, 2, 3, 4, 5, 6, 7].map(key => (
                        <Suspense fallback={<div>Loading...</div>}>
                          <Grid xs={6} md={6} sm={6} xl={3} lg={3} className={classes.CardContainer}>
                            <CardItem
                              isAddItem={isAddItem}
                              handleAddItem={handleAddItem}
                              index={key}
                              typeUnit='dummy'
                              discountUnit={0}
                              handleDeleteItem={handleDeleteItem}
                              hasDebt={hasDebt}
                              handleProductStatus={handleProductStatus}
                              isActive={false}
                              isUpdate={isUpdate}
                              productSelectedItem={productSelectedItem}
                              handleOpenImage={() => console.log('')}
                              id={key}
                              isNew={false}
                              isProductPackage={false}
                              netStockSatuan={0}
                              isReStock={false}
                              productName='Philips Dry Iron - HD1172/99'
                              productId='PH12564'
                              updateListItem={updateListItem}
                              productLoading={status === 'loading'}
                              imageUrl='-'
                              isAllowAction={false}
                              stock={12}
                              stockDamaged={0}
                              holdItem={0}
                              qtyAvailable={12}
                              wareHouse='Avian'
                              price={1000}
                              setHardSelling={() => console.log('')}
                              setPopularItem={() => console.log('')}
                              populerItem={false}
                              hardSelling={false}
                            />
                          </Grid>
                        </Suspense>
                      ))
                    ) : data && data.data.length > 0 ? (
                      data?.data.map((val, key: number) => (
                        <Suspense fallback={<div>Loading...</div>}>
                          <Grid xs={6} md={6} sm={6} xl={3} lg={3} className={classes.CardContainer}>
                            <CardItem
                              isAddItem={isAddItem}
                              id={val.id}
                              handleAddItem={handleAddItem}
                              hasDebt={hasDebt}
                              isUpdate={isUpdate}
                              handleOpenImage={handleOpenImage}
                              typeUnit={val.typeUnit}
                              productSelectedItem={productSelectedItem}
                              updateListItem={updateListItem}
                              isNew={val.new || false}
                              isReStock={val.reStock || false}
                              discountUnit={0}
                              handleProductStatus={handleProductStatus}
                              isActive={val.isActive}
                              isAllowAction={isAllowAction}
                              qtyAvailable={val.totalStock}
                              handleDeleteItem={handleDeleteItem}
                              index={val.id}
                              productLoading={productLoading}
                              productName={val.productName}
                              productId={val.productCode}
                              imageUrl={(val.ProductImage && val.ProductImage.url) || ''}
                              stock={val.isProductPackage && val.isProductPackage ? val.netStock || 0 : (val.netStock || 0) - (val.holdItem || 0)}
                              isProductPackage={val.isProductPackage || false}
                              netStockSatuan={(val.netStockSatuan || 0) - (val.holdItem || 0)}
                              holdItem={val.holdItem || 0}
                              stockDamaged={(val?.totalDamage || 0) + (val?.totalDamageEdit || 0)}
                              wareHouse={val.warehouse || ''}
                              price={getPrice(val.id) || 0}
                              setHardSelling={setHardSelling}
                              setPopularItem={setPopularItem}
                              populerItem={val.populerItem}
                              hardSelling={val.hardSelling}
                            />
                          </Grid>
                        </Suspense>
                      ))
                    ) : (
                      <Grid container style={{ minHeight: '20vh' }} justify='center' alignItems='center' xs={12}>
                        <Typography variant='h6'>Produk tidak ditemukan</Typography>
                      </Grid>
                    )}
                  </Grid>
                  {showComponent && (
                    <React.Fragment>
                      <Grid id='back-to-top' />
                      <InfiniteScroll
                        dataLength={dataProduct?.length ?? 0}
                        next={() => fetchNextPage()}
                        hasMore={!!hasNextPage}
                        loader={
                          isFetchingNextPage &&
                          statusInfinite !== 'loading' && (
                            <Grid xs={12} style={{ margin: '1.5em 0' }} container justify='center'>
                              <Loading size='small' />
                            </Grid>
                          )
                        }
                      >
                        <Grid className={classes.sectionProductMobile}>
                          {statusInfinite === 'loading' ? (
                            [0, 1, 2, 3].map(key => (
                              <Suspense fallback={<div>Loading...</div>}>
                                <Grid style={{ width: '43vw' }} className={classes.CardContainer}>
                                  <CardItem
                                    isAddItem={isAddItem}
                                    handleAddItem={handleAddItem}
                                    index={key}
                                    hasDebt={hasDebt}
                                    isUpdate={isUpdate}
                                    typeUnit='dummy'
                                    productSelectedItem={productSelectedItem}
                                    discountUnit={0}
                                    handleProductStatus={handleProductStatus}
                                    isActive={false}
                                    handleOpenImage={() => console.log('')}
                                    handleDeleteItem={handleDeleteItem}
                                    id={key}
                                    productName='Philips Dry Iron - HD1172/99'
                                    productId='PH12564'
                                    updateListItem={updateListItem}
                                    productLoading={true}
                                    isNew={false}
                                    isProductPackage={false}
                                    isAllowAction={false}
                                    netStockSatuan={0}
                                    isReStock={false}
                                    imageUrl='-'
                                    stock={12}
                                    stockDamaged={0}
                                    holdItem={0}
                                    qtyAvailable={12}
                                    wareHouse='Avian'
                                    price={1000}
                                    setHardSelling={() => console.log('')}
                                    setPopularItem={() => console.log('')}
                                    populerItem={false}
                                    hardSelling={false}
                                  />
                                </Grid>
                              </Suspense>
                            ))
                          ) : dataProduct && dataProduct.length > 0 ? (
                            dataProduct &&
                            dataProduct.map((val, key: number) => (
                              <Suspense fallback={<div>Loading...</div>}>
                                <Grid style={{ width: '43vw' }} className={classes.CardContainer} key={key}>
                                  <CardItem
                                    isAddItem={isAddItem}
                                    id={val.id}
                                    hasDebt={hasDebt}
                                    isUpdate={isUpdate}
                                    handleAddItem={handleAddItem}
                                    typeUnit={val.typeUnit}
                                    updateListItem={updateListItem}
                                    handleOpenImage={handleOpenImage}
                                    isAllowAction={isAllowAction}
                                    handleDeleteItem={handleDeleteItem}
                                    discountUnit={0}
                                    isNew={val.new || false}
                                    isReStock={val.reStock || false}
                                    handleProductStatus={handleProductStatus}
                                    isActive={val.isActive}
                                    qtyAvailable={val.totalStock}
                                    index={val.id}
                                    productLoading={false}
                                    productSelectedItem={productSelectedItem}
                                    productName={val.productName}
                                    productId={val.productCode}
                                    imageUrl={(val.ProductImage && val.ProductImage.url) || ''}
                                    stock={
                                      val.isProductPackage && val.isProductPackage ? val.netStock || 0 : (val.netStock || 0) - (val.holdItem || 0)
                                    }
                                    isProductPackage={val.isProductPackage || false}
                                    netStockSatuan={(val.netStockSatuan || 0) - (val.holdItem || 0)}
                                    holdItem={val.holdItem || 0}
                                    wareHouse={val.warehouse || ''}
                                    stockDamaged={(val?.totalDamage || 0) + (val?.totalDamageEdit || 0)}
                                    price={
                                      Number(
                                        val.ProductPrice && val.ProductPrice.filter(value => value.ZoneId === zone.id).map(value => value.price)
                                      ) || 0
                                    }
                                    setHardSelling={setHardSelling}
                                    setPopularItem={setPopularItem}
                                    populerItem={val.populerItem}
                                    hardSelling={val.hardSelling}
                                  />
                                </Grid>
                              </Suspense>
                            ))
                          ) : (
                            <Grid container style={{ minHeight: '20vh' }} justify='center' alignItems='center' xs={12}>
                              <Typography variant='h6'>Produk tidak ditemukan</Typography>
                            </Grid>
                          )}
                        </Grid>
                      </InfiniteScroll>
                      <Zoom in={trigger}>
                        <Grid className={classes.upButton}>
                          <IconButton color='primary' aria-label='up icon' component='span' className={classes.upButtonArrow} onClick={handleBackTop}>
                            <ArrowUpward />
                          </IconButton>
                        </Grid>
                      </Zoom>
                    </React.Fragment>
                  )}
                </Grid>
                {!isAllowCreateSO && (
                  <Grid xs={12} container justify='center' className={classes.buttonBottom}>
                    <Grid xs={11} sm={11} md={11} lg={12} xl={12}>
                      <Button
                        variant='contained'
                        fullWidth
                        color='primary'
                        style={{ borderRadius: '20px', position: 'relative' }}
                        disabled={zone.name === '' || isUpdate || productSelectedItem.length < 1 || hasDebt}
                        onClick={handlePriview}
                        endIcon={<ArrowForward style={{ position: 'absolute', right: 21, bottom: 8 }} />}
                      >
                        Lanjutkan ({productSelectedItem.length})
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </Fragment>
            ) : (
              <Grid xs={12} container>
                <Grid xs={12} className={classes.spacing}>
                  <Divider />
                </Grid>
                <Grid xs={12} justify='space-between' container className={classes.spacing}>
                  <Typography variant='h5'>Review Produk</Typography>
                  <Button startIcon={<Add />} onClick={() => setIsPreview(false)}>
                    Produk Lainnya
                  </Button>
                </Grid>
                {productSelectedItem &&
                  productSelectedItem.map((val: OrderItems, key: number) => (
                    <Grid xs={12} sm={12} md={12} xl={3} lg={3}>
                      <Grid xs={11} key={key} className={classes.productPriview}>
                        <ProductReview
                          imageUrl={val.imageUrl}
                          productName={val.productName}
                          onClickEdit={() => {
                            handleClickEdit(val.ProductId);
                            setQtyEdit(val.totalItem);
                            setDiscount(val.discountUnit);
                            setPrice(val.price);
                          }}
                          handleOpenImage={handleOpenImage}
                          discountResource={discountResource}
                          onClickDelete={() => handleDelete(val.ProductId)}
                          price={val.price}
                          expandHistory={expandHistory}
                          productId={val.ProductId}
                          note={val.note}
                          handleChangeNote={handleChangeNote}
                          setExpandHistory={setExpandHistory}
                          handleShowHistory={handleShowHistory}
                          idClickDiscount={idClickDiscount}
                          qtyRequest={val.totalItem}
                          discountUnit={val.discountUnit}
                          typeUnit={val.typeUnit}
                        />
                      </Grid>
                    </Grid>
                  ))}
                <Grid xs={12} container justify='center' className={classes.buttonBottom}>
                  <Grid xs={11} sm={11} md={11} lg={12} xl={12}>
                    <Button
                      variant='contained'
                      fullWidth
                      color='primary'
                      style={{ borderRadius: '20px' }}
                      onClick={handleSubmit}
                      disabled={productSelectedItem.length < 1}
                    >
                      {isSubmit && <CircularProgress size={20} color='secondary' />}
                      {!isSubmit && 'Buat Order Penjualan'}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            )}

            <ModalDiscount
              showComponent={showComponent}
              openEdit={openEdit}
              qtyEdit={qtyEdit}
              setQtyEdit={setQtyEdit}
              discountResource={discountResource}
              setPrice={setPrice}
              price={price}
              setDiscount={setDiscount}
              discount={discount}
              setOpenEdit={setOpenEdit}
              handleEditItem={handleEditItem}
              productSelectedItem={productSelectedItem}
              idClick={idClick}
            />
            <ModalCatalog
              open={downloadCatalog}
              category={categoryDownload}
              categories={categoriesDownload}
              isLoadingCategories={isLoadingCategories}
              handleDownload={handleDownload}
              isLoadingPrint={isDownload}
              handleCloseDownload={handleCloseDownload}
              setCategory={setCategoryDownload}
            />
            <StandardConfirmationDialog
              variant={snackbarVariant}
              titleMessage={snackbarVariant === 'success' ? 'Success!' : 'Error!'}
              message={message}
              open={openSnackbar}
              handleClose={snackbarVariant === 'success' ? handleConfirmSnackbar : handleCloseSnackbar}
              onConfirm={handleConfirmSnackbar}
              noCancelButton={true}
            />

            <StandardConfirmationDialog
              variant={snackbarVariant}
              titleMessage={snackbarVariant === 'success' ? 'Success!' : 'Error!'}
              message={message}
              open={openSnackbarProduct}
              handleClose={snackbarVariant === 'success' ? handleConfirmSnackbarProduct : handleCloseSnackbar}
              onConfirm={handleConfirmSnackbarProduct}
              noCancelButton={true}
            />
          </Grid>
        </PaperCustom>

        <ProductModal open={open} setOpen={setOpen} handleSnackBar={handleSnackBarProduct} setProducts={setProducts} />

        <ProductPackage
          products={products}
          open={openPackage}
          setOpen={setOpenPackage}
          setProducts={setProducts}
          handleSnackBar={handleSnackBarProduct}
        />

        <StandardConfirmationDialog
          variant={'danger'}
          titleMessage={'Delete'}
          message={'Apakah kamu yakin menghapus data ini?'}
          open={confirmationDelete}
          handleClose={handleCloseConfirmationDelete}
          onConfirm={deleteProduct}
        />
      </Container>
    </Page>
  );
};

export default ProductPage;
