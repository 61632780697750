export const modules = {
  SALES_ORDER: 'SALES-ORDER',
  PURCHASE_ORDER: 'PURCHASE-ORDER',
  PURCHASE_INVOICE: 'PURCHASE-INVOICE',
  HOLDING_ORDER: 'HOLDING-ORDER',
  INVOICE_ORDER: 'INVOICE-ORDER',
  STOCK: 'STOCK',
  INVOICE: 'INVOICE',
  INVOICE_PAYMENT: 'INVOICE-PAYMENT',
  COMMISSION: 'COMMISSION',
  PARTNER: 'PARTNER',
  PRODUCT: 'PRODUCT',
  CATEGORIES: 'CATEGORIES',
  INVOICE_RETURN: 'INVOICE-RETURN',
  DELIVERY: 'DELIVERY',
  HOLDING_RETURN: 'HOLDING-RETURN',
  FINANCE: 'FINANCE',
  USERS: 'USERS',
  COMPANY: 'COMPANY',
  WAREHOUSE: 'WAREHOUSE',
  ZONE: 'ZONE',
  APPLOG: 'APPLOG'
};
