import React, { FC, useState, useEffect, useContext } from 'react';
import { Container, makeStyles, Typography, Grid, Button, Tooltip } from '@material-ui/core';
import { Page, StandardConfirmationDialog, Breadcrumb, PaperCustom } from 'components';
import {
  PARTNER_BASE_URL,
  INVOICE_BASE_URL,
  PURCHASE_INVOICE_BASE_URL,
  GET_PURCHASE_INVOICE_ITEMS_BASE_URL,
  RETURN_INVOICE_BASE_URL
} from 'constants/url';
import { PRIMARY_MOBILE, WHITE } from 'constants/colors';
import { dummyPartner } from 'utils/dummy';
import { CurrentUserContext } from 'contexts/CurrentUserContext';
import axios from 'axios';
import useRouter from 'hooks/useRouter';
import PartnerDetail from './components/PartnerDetail';
import PartnerWizard from './components/PartnerWizard';
import NumberFormat from 'react-number-format';
import InvoiceTable from './components/InvoiceTable';
import PurchaseInvoice from './components/PurchaseInvoice';
import RefreshIcon from '@material-ui/icons/Refresh';
import useRole from 'hooks/useRole';
import useDebounced from 'hooks/useDebounced';
import TypeUser from 'typings/enum/TypeUser';
import { format } from 'date-fns';
import monthNames from 'utils/month';

const useStyles = makeStyles({
  partnerWrapper: {
    marginTop: '1em',
    padding: ' 1em 24px',
    borderRadius: '.2em',
    boxShadow: '0px 5px 12px rgba(0, 0, 0, 0.08)',
    width: '100%'
  },
  secondGrid: {
    marginTop: 10
  },
  box: {
    backgroundColor: WHITE,
    borderRadius: '5px',
    padding: 24,
    border: '1px solid #F2F2F2',
    boxShadow: '0px 5px 12px rgba(0, 0, 0, 0.08)',
    marginBottom: '10px'
  },
  boxTitle: {
    marginBottom: 8
  }
});

enum PartnerType {
  SUPPLIER = 'SUPPLIER',
  CUSTOMER = 'CUSTOMER'
}

const PartnerDetailPage: FC = () => {
  const classes = useStyles();
  const { currentUser } = useContext(CurrentUserContext);
  const { history } = useRouter();
  // eslint-disable-next-line
  const { match } = useRouter();
  const params = match.params.id;
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
  const [partner, setPartner] = useState<PartnerModel>(dummyPartner);
  const [id, setId] = useState<number>();
  const [confirmationDelete, setConfirmationDelete] = useState<boolean>(false);
  const [snackbarVariant, setSnackbarVariant] = useState<'success' | 'error'>('success');
  const [message, setMessage] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [isSubmit, setSubmit] = useState<boolean>(false);
  const [isUpdate, setUpdate] = useState<boolean>(false);
  const [count, setCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = useState<string>('id');
  const [invoiceId, setInvoiceId] = useState<string>('');
  const [invoices, setInvoices] = useState<InvoiceModel[]>([]);
  const [purchaseInvoices, setPurchaseInvoices] = useState<PurchaseInvoiceModel[]>([]);
  const [openCollapse, setOpenCollapse] = useState<boolean>(false);
  const [indexCollapse, setIndexCollapse] = useState<number>(-1);
  const [statusPayment, setStatusPayment] = useState<string>('');
  const [statusReturn, setStatusReturn] = useState<string>('');

  const [loadInvoice, setLoadInvoice] = useState<boolean>(false);
  const [isDelete, setDelete] = useState<boolean>(false);
  const [statusInvoice, setStatusInvoice] = useState<string>('CONFIRMATION');
  const tokenSource = axios.CancelToken.source();
  const [collapseLoading, setCollapseLoading] = useState<boolean>(false);

  const isSuperVisorIsSales = useRole({
    type: (currentUser && currentUser.type) || 'SALES',
    allowed: [TypeUser.SUPERVISOR, 'SALES']
  });

  const isAdmin04 = useRole({
    type: (currentUser && currentUser.type) || 'SALES',
    allowed: [TypeUser.ADMIN04, TypeUser.ACCOUNTING]
  });
  const isHR = useRole({
    type: (currentUser && currentUser.type) || 'SALES',
    allowed: [TypeUser.ADMIN04, TypeUser.HR]
  });

  const fetchData = async () => {
    setIsLoadingData(true);
    try {
      const { data } = await axios.get(`${PARTNER_BASE_URL}/${params}`);
      setPartner(data.data);
      setId(data.data.id);
    } catch (error) {
      console.log('error:', error);
    } finally {
      setIsLoadingData(false);
    }
  };

  const fetchInvoice = async () => {
    const getQueryParams = () => {
      const param = new URLSearchParams();

      if (invoiceId) {
        param.append('number', invoiceId);
      }

      param.append('status', statusPayment);
      if (statusReturn !== '') {
        param.append('statusReturn', statusReturn);
      }
      param.append('orderBy', orderBy);
      param.append('ordered', order);
      param.append('page', String(currentPage));
      param.append('partnerId', String(params));
      param.append('type', String(partner.partnerType));

      return param.toString();
    };

    setLoadInvoice(true);
    try {
      const { data } = await axios.get(`${INVOICE_BASE_URL}/mitra-detail?${getQueryParams()}`, {
        cancelToken: tokenSource.token
      });
      if (partner.partnerType === PartnerType.SUPPLIER) {
        setPurchaseInvoices(data.data);
      } else {
        setInvoices(data.data);
      }
      setCount(data.meta.last_page);
    } catch (error) {
      console.log('error: ', error);
    } finally {
      setLoadInvoice(false);
    }
  };

  const getData = async (id: number) => {
    setCollapseLoading(true);
    try {
      const { data } = await axios.get(`${INVOICE_BASE_URL}/${id}`);

      setInvoices(prevState =>
        prevState.map(value => {
          if (value.id === id) {
            value.InvoiceItem = data.data.InvoiceItem;
          }
          return value;
        })
      );
      setCollapseLoading(false);
    } catch (err) {
      console.log('err', err);
    } finally {
    }
  };

  const getDataReturnOrder = async (id: number) => {
    setCollapseLoading(true);
    try {
      const { data } = await axios.get(`${RETURN_INVOICE_BASE_URL}/${id}/items`);
      const dataTemp = data.data.map((val: any) => {
        return {
          totalItem: val.totalItem,
          totalPrice: val.totalPrice,
          productName: val.Product.productName
        };
      });

      setInvoices(prevState =>
        prevState.map(value => {
          if (value.id === id) {
            value.InvoiceItem = dataTemp;
          }
          return value;
        })
      );
      setCollapseLoading(false);
    } catch (err) {
      console.log('err', err);
    }
  };

  const getDataReturnPurchase = async (id: number) => {
    setCollapseLoading(true);
    try {
      const { data } = await axios.get(`${RETURN_INVOICE_BASE_URL}/${id}/items`);

      setPurchaseInvoices(prevState =>
        prevState.map(value => {
          if (value.id === id) {
            value.PurchaseInvoiceItem = data.data;
          }
          return value;
        })
      );
      setCollapseLoading(false);
    } catch (err) {
      console.log('err', err);
    }
  };

  const handleConfirmationDelete = () => {
    setConfirmationDelete(true);
  };

  const handleCloseConfirmationDelete = () => {
    setConfirmationDelete(false);
  };

  const deletePartner = async () => {
    try {
      await axios.delete(`${PARTNER_BASE_URL}/${id}`);
      handleSnackBar(true, 'success', 'Mitra berhasil dihapus.');
      setDelete(true);
    } catch (err) {
      console.log(err);
      handleSnackBar(true, 'error', 'Mitra gagal dihapus.');
    } finally {
      setConfirmationDelete(false);
    }
  };

  const handleSnackBar = (open: boolean, variant: 'success' | 'error', message: string) => {
    setSnackbarVariant(variant);
    setOpenSnackbar(open);
    setMessage(message);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
    if (isDelete) {
      setDelete(false);
      history.push('/mitra');
    }
  };

  const handleOnSubmit = () => {
    setSubmit(true);
  };

  const handleSetUpdate = () => {
    setUpdate(true);
  };

  const handleCancelUpdate = () => {
    setUpdate(false);
  };

  const handleOpenCollapse = (index: number, id: number, type?: string): React.MouseEventHandler => () => {
    setIndexCollapse(index);

    setOpenCollapse(openCollapse ? (index === indexCollapse ? false : true) : true);
    if (type && type === 'ReturnCustomer') {
      getDataReturnOrder(id);
    } else {
      getData(id);
    }
  };

  const handleOpenCollapsePurchase = (index: number, type?: string): React.MouseEventHandler => () => {
    setIndexCollapse(index);
    setOpenCollapse(openCollapse ? (index === indexCollapse ? false : true) : true);
    if (type === 'returnSuplier') {
      getDataReturnPurchase(index);
    } else {
      fetchItems(index);
    }
  };

  const fetchItems = async (id: number) => {
    setCollapseLoading(true);
    try {
      const { data } = await axios.get(GET_PURCHASE_INVOICE_ITEMS_BASE_URL(id));

      setPurchaseInvoices(prevState =>
        prevState.map(value => {
          if (value.id === id) {
            value.PurchaseInvoiceItem = data.data;
          }
          return value;
        })
      );
    } catch (err) {
      console.log(err);
    } finally {
      setCollapseLoading(false);
    }
  };

  useEffect(() => {
    setIsLoadingData(true);
    fetchData();
  }, []);

  const debounceHits = useDebounced(fetchInvoice, 500);

  useEffect(() => {
    if (partner.id === 0) return;
    debounceHits();

    return () => tokenSource.cancel('query is cancelled');
  }, [invoiceId, statusPayment, orderBy, currentPage, params, partner, statusReturn]);

  return (
    <Page title='Mitra'>
      <Container>
        <Grid container direction='row'>
          <Grid item lg={12} sm={12} md={12} xs={12}>
            <Typography variant='h1'> Semua Mitra </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Breadcrumb />
          </Grid>
        </Grid>

        <Grid container className={classes.partnerWrapper} alignItems='center'>
          <Grid xs={6}>
            <Typography variant='h6'>
              Ditambahkan Oleh:
              {partner.createdby?.startsWith(' ') ? '-' : <span style={{ fontWeight: 800, color: PRIMARY_MOBILE }}> {partner.createdby ?? '-'}</span>}
            </Typography>
            <Typography>
              {(partner.createdAt &&
                `Tanggal  
                ${new Date(partner.createdAt).getDate() < 10 ? `0${new Date(partner.createdAt).getDate()}` : new Date(partner.createdAt).getDate()} 
                ${monthNames[new Date(partner.createdAt).getMonth()]}
                ${new Date(partner.createdAt).getFullYear()}, jam ${new Date(partner.createdAt).getHours()}:${
                  new Date(partner.createdAt).getMinutes() < 10
                    ? `0${new Date(partner.createdAt).getMinutes()}`
                    : new Date(partner.createdAt).getMinutes()
                } WIB`) ||
                '-'}
            </Typography>
          </Grid>

          <Grid xs={6} container direction='row' spacing={1} justify='flex-end'>
            <Grid item>
              <Tooltip title='Refresh'>
                <Button onClick={() => fetchData()} color='inherit'>
                  <RefreshIcon />
                </Button>
              </Tooltip>
            </Grid>

            {!isSuperVisorIsSales && !isAdmin04 && !isHR && (
              <>
                <Grid item>
                  <Button color='secondary' onClick={isUpdate ? handleCancelUpdate : handleConfirmationDelete}>
                    <Typography>{isUpdate ? 'Batal' : 'Hapus'}</Typography>
                  </Button>
                </Grid>
                <Grid item>
                  <Button onClick={isUpdate ? handleOnSubmit : handleSetUpdate}>
                    <Typography>{isUpdate ? 'Simpan' : 'Edit Data'}</Typography>
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
        {isUpdate ? (
          <PartnerWizard
            typeUser={(currentUser && currentUser.type) || 'SALES'}
            partner={partner}
            setPartner={setPartner}
            isSubmit={isSubmit}
            setUpdate={setUpdate}
            setSubmit={setSubmit}
            handleSnackBar={handleSnackBar}
          />
        ) : (
          <PartnerDetail partner={partner} isLoading={isLoadingData} />
        )}

        <Grid container direction='row' justify='space-between' className={classes.box}>
          <Grid item lg={4} sm={12} md={4} xs={12}>
            <Typography align='center' variant='h5' className={classes.boxTitle}>
              Tipe Mitra
            </Typography>
            <Typography align='center' variant='body2'>
              {partner.partnerType}
            </Typography>
          </Grid>

          <Grid item lg={4} sm={12} md={4} xs={12}>
            <Typography align='center' variant='h5' className={classes.boxTitle}>
              Jumlah Terhutang
            </Typography>
            <Typography align='center' variant='body2'>
              <NumberFormat value={partner.totalBill ? partner.totalBill : 0} prefix={'Rp'} thousandSeparator={true} displayType='text' />
            </Typography>
          </Grid>

          <Grid item lg={4} sm={12} md={4} xs={12}>
            <Typography align='center' variant='h5' className={classes.boxTitle}>
              Jumlah Return
            </Typography>
            <Typography align='center' variant='body2'>
              <NumberFormat value={partner.totalReturn || 0} prefix={'Rp'} thousandSeparator={true} displayType='text' />
            </Typography>
          </Grid>
        </Grid>

        {partner.partnerType === PartnerType.SUPPLIER ? (
          <PurchaseInvoice
            statusPayment={statusPayment}
            purchaseInvoiceId={invoiceId}
            isLoadingData={isLoadingData}
            count={count}
            currentPage={currentPage}
            purchaseInvoices={purchaseInvoices}
            order={order}
            orderBy={orderBy}
            openCollapse={openCollapse}
            indexCollapse={indexCollapse}
            statusInvoice={statusInvoice}
            setStatusInvoice={setStatusInvoice}
            collapseLoading={collapseLoading}
            setInvoiceId={setInvoiceId}
            setStatusPayment={setStatusPayment}
            setOrder={setOrder}
            setOrderBy={setOrderBy}
            handleOpenCollapse={handleOpenCollapsePurchase}
          />
        ) : (
          <InvoiceTable
            statusPayment={statusPayment}
            invoiceId={invoiceId}
            isLoadingData={loadInvoice}
            count={count}
            currentPage={currentPage}
            invoices={invoices}
            order={order}
            orderBy={orderBy}
            collapseLoading={collapseLoading}
            openCollapse={openCollapse}
            indexCollapse={indexCollapse}
            setInvoiceId={setInvoiceId}
            setStatusReturn={setStatusReturn}
            statusReturn={statusReturn}
            setStatusPayment={setStatusPayment}
            setOrder={setOrder}
            setOrderBy={setOrderBy}
            handleOpenCollapse={handleOpenCollapse}
            setCurrentPage={setCurrentPage}
          />
        )}

        <StandardConfirmationDialog
          variant={snackbarVariant}
          titleMessage={snackbarVariant === 'success' ? 'Success!' : 'Error!'}
          message={message}
          open={openSnackbar}
          handleClose={handleCloseSnackbar}
          onConfirm={handleCloseSnackbar}
          noCancelButton={true}
        />

        <StandardConfirmationDialog
          variant={'danger'}
          titleMessage={'Delete'}
          message={'Apakah kamu yakin menghapus data ini?'}
          open={confirmationDelete}
          handleClose={handleCloseConfirmationDelete}
          onConfirm={deletePartner}
        />
      </Container>
    </Page>
  );
};

export default PartnerDetailPage;
