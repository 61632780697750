import React, { FC, Fragment, useCallback, useEffect, useState } from 'react';
import { Grid, TableRow, IconButton, Button, Typography, makeStyles, Tooltip, Table, TableBody, TableCell, TableHead } from '@material-ui/core';
import { TableCellMiddle, TableCellEnd, TableCellStart, TableRowCollapse } from 'components/Table';
import Skeleton from '@material-ui/lab/Skeleton';
import NumberFormat from 'react-number-format';
import CheckMarkItem from 'images/icon/CheckMarkItem.svg';
import CloseCircleItem from 'images/icon/CloseCircleItem.svg';
import DontIcon from 'images/icon/DontIcon.svg';
import { RED, WHITE, GREEN, ORANGE, BLACK, GREY, YELLOW_MOBILE } from 'constants/colors';
import ItemStatus from 'typings/enum/ItemStatus';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import clx from 'clsx';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import BodyItem from './BodyItem';
import theme from 'theme/hady';
import TypeUser from 'typings/enum/TypeUser';
import SalesOrderStatus from 'typings/enum/SalesOrderStatus';
import { remarkCode } from 'constants/remarkCode';
import axios from 'axios';
import { CHANGE_STATUS_SALES_ORDER_ITEM } from 'constants/url';
import { values } from 'lodash';
import { InfoRounded } from '@material-ui/icons';

enum statusConditaion {
  reject = 'reject',
  accept = 'accept'
}
interface Props {
  salesOrder: SalesOrderModel;
  rejectOrder: ItemAction[];
  salesOrderItem: SalesOrderItemModel;
  loadingReject: boolean;
  collyFinal: typeCollyFinal;
  isLoading: boolean;
  handleConfirm: React.MouseEventHandler;
  handleReject: React.MouseEventHandler;
  addItemAction: (value: ItemAction) => void;
  handleRejectSuperAdmin: (value: ItemAction) => void;
  handleCancelRejectSuperAdmin: (value: ItemAction) => void;
  tempItemColly: (data: typeColly) => void;
  deleteColly: (id: number) => void;
  handleOpenImage: (imagePath: string) => void;
  tab: number;
  index: number;
  isAccess: string;
  hasInvoice: boolean;
  openCollapse: boolean;
  openTooltip: boolean;
  indexCollapse: number;
  indexTooltip: number;
  isConvertAllowed: boolean;
  currentWareHouse: string;
  setGetIdClick: React.Dispatch<React.SetStateAction<number>>;
  handleOpenCollapse: React.MouseEventHandler;
  handleOpenTooltip: React.MouseEventHandler;
  handleCloseTooltip: () => void;
  setOpenEdit: React.Dispatch<React.SetStateAction<boolean>>;
  setHasOpenModal: React.Dispatch<React.SetStateAction<number>>;
  openAlertPrice: boolean;
  indexAlertPrice: number;
  handleOpenAlertPrice: React.MouseEventHandler;
  handleCloseAlertPrice: () => void;
  handleAcceptItem: (id: number) => void;
  handleRejectItem: (id: number) => void;
  handletRequestEdit: (id: number, productId: number, totalQty: number, remark: string, remarkCode: string) => void;
  openAlertDiscount: boolean;
  indexAlertDiscount: number;
  handleOpenAlertDiscount: React.MouseEventHandler;
  handleCloseAlertDiscount: () => void;
}

const useStyles = makeStyles({
  confirm: {
    background: WHITE,
    color: GREEN
  },
  reject: {
    background: WHITE,
    color: RED
  },
  discount: {
    background: WHITE,
    color: ORANGE
  },
  tableCollapse: {
    width: '100%'
  },
  cellCollapse: {
    border: 'none'
  },
  table: {
    backgroundColor: 'rgba(255, 255, 255, 0.87)',
    maxWidth: 220,
    fontSize: '12pt',
    border: '1px solid #dadde9'
  },
  iconPrice: {
    verticalAlign: 'middle',
    marginLeft: 8
  },
  highlight: {
    boxShadow: '0px 2px 4px rgba(0, 136, 186, 0.4)',
    borderRadius: 4
  },
  done: {
    backgroundColor: '#e0e0e0'
  },
  productImage: {
    width: '100%',
    height: '100%'
  },
  defaultImage: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    color: '#fff'
  },
  bodyContainer: {
    border: '1px solid #E0E0E0',
    borderRadius: '5px',
    marginBottom: theme.spacing(2),

    padding: theme.spacing(1)
  },
  containerRight: {
    display: 'flex'
  },
  descriptionContainer: {
    backgroundColor: WHITE,
    borderRadius: '6px',
    boxShadow: '0 2px 5px rgb(0 0 0 / 14%)',
    border: '1px solid #E0E0E0'
  },
  description: {
    color: BLACK
  },
  warning: {
    color: YELLOW_MOBILE,
    marginRight: '.3em',
    marginTop: '.3em'
  }
});

const BodyRow: FC<Props> = props => {
  const classes = useStyles();
  const [isEdit, setIsEdit] = useState(false);
  const {
    salesOrderItem,
    isLoading,
    rejectOrder,
    isAccess,
    loadingReject,
    handleRejectSuperAdmin,
    handleCancelRejectSuperAdmin,
    index,
    handleAcceptItem,
    handleRejectItem,
    tempItemColly,
    deleteColly,
    isConvertAllowed,
    addItemAction,
    handletRequestEdit,
    handleReject,
    setGetIdClick,
    handleConfirm,
    setHasOpenModal,
    setOpenEdit,
    handleOpenCollapse,
    handleOpenTooltip,
    handleCloseTooltip,
    openCollapse,
    indexCollapse,
    handleOpenImage,
    indexTooltip,
    openTooltip,
    tab,
    salesOrder,
    currentWareHouse,
    openAlertPrice,
    indexAlertPrice,
    handleOpenAlertPrice,
    openAlertDiscount,
    indexAlertDiscount,
    handleOpenAlertDiscount
  } = props;

  const warehouse = salesOrderItem.warehouse || 'NO_WAREHOUSE';
  const currentPrice = salesOrderItem.price;
  const productPrice =
    salesOrderItem.Product &&
    salesOrderItem.Product.ProductPrice &&
    salesOrderItem.Product.ProductPrice.find(value => value.ZoneId === salesOrder.Partner!.Zone!.id);
  const totalProductPrice = productPrice ? productPrice.price * salesOrderItem.totalItem : 0;
  const [checked, setChecked] = React.useState<boolean>(false);
  const [newQty, setNewQty] = useState<number>(salesOrderItem.totalItem);
  const [isEditReject, setIsEditReject] = useState(0);
  const handleInvoice = (id: number) => {
    window.open(`/invoice/${id}`, '_blank');
  };
  const handleOpenModal = () => {
    setHasOpenModal(10);
    setGetIdClick(salesOrderItem.id);
    setOpenEdit(true);
  };

  const handleOpenEdit = () => {
    setOpenEdit(true);
    setHasOpenModal(2);
    setGetIdClick(salesOrderItem.id);
  };
  const handleOpenEditQty = () => {
    setOpenEdit(true);
    setHasOpenModal(1);
    setGetIdClick(salesOrderItem.id);
  };

  const handleEdit = () => {
    setIsEdit(true);
  };

  const handleAccept = () => {
    if (!rejectOrder.some(val => val.id === salesOrderItem.id)) {
      addItemAction!({
        id: salesOrderItem.id,
        status: isAccess === TypeUser.ADMIN01 ? SalesOrderStatus.PACKING : isAccess === TypeUser.PICKER ? SalesOrderStatus.PREVIEW : '',
        remark: `${isAccess} telah melakukan pengecekan`,
        remarkCode: remarkCode.ACCEPT
      });
    }
    handleAcceptItem(salesOrderItem.id);
  };

  const UpdateItemQty = () => {
    handletRequestEdit(salesOrderItem.id, salesOrderItem.ProductId, newQty, `${isAccess} telah melakukan pengeditan qty`, remarkCode.EDIT);
    setIsEdit(false);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setChecked(event.target.checked);
    if (event.target.checked) {
      tempItemColly({
        id: 0,
        SalesOrderItemId: salesOrderItem.id,
        totalItem: salesOrderItem.totalItem - salesOrderItem.totalInBag,
        totalItemBefore: salesOrderItem.totalItem - salesOrderItem.totalInBag,
        imageUrl: salesOrderItem.productImage,
        productName: (salesOrderItem.productName && salesOrderItem.productName) || '',
        typeUnit: salesOrderItem.typeUnit,
        wareHouse: salesOrderItem.warehouse
      });
    } else {
      deleteColly(salesOrderItem.id);
    }
  };

  const handleRejectItemAction = () => {
    if (!rejectOrder.some(val => val.id === salesOrderItem.id)) {
      addItemAction!({ id: salesOrderItem.id, status: SalesOrderStatus.REJECT, remark: '', remarkCode: remarkCode.REJECT });
    }
    handleRejectItem(salesOrderItem.id);
  };

  const handleIsAction = () => {
    setIsEdit(false);
    setNewQty(salesOrderItem.totalItem);
  };

  const handleCancelRejectSuper = () => {
    setIsEditReject(salesOrderItem.ProductId);
    const newData = [
      {
        id: salesOrderItem.id,
        remark: 'Super Admin membatalkan penolakan',
        remarkCode: remarkCode.ACCEPT,
        status: SalesOrderStatus.PENDING,
        rejectBy: isAccess
      }
    ];
    handleCancelRejectSuperAdmin({ ...newData[0] });
  };
  const handleRejectSuper = () => {
    setIsEditReject(salesOrderItem.ProductId);
    const newData = [
      {
        id: salesOrderItem.id,
        remark: 'Super Admin melakukan penolakan',
        remarkCode: remarkCode.REJECT,
        status: SalesOrderStatus.REJECT,
        rejectBy: isAccess
      }
    ];
    handleRejectSuperAdmin({ ...newData[0] });
  };

  const handleAction = () => {
    if (tab === 0) {
      if (salesOrderItem.status === ItemStatus.PENDING && isConvertAllowed) {
        return (
          <Fragment>
            <Typography variant='body2'>Sedang Menunggu</Typography>
          </Fragment>
        );
      } else {
        if (salesOrderItem.status === ItemStatus.CONFIRM) {
          return <Typography variant='body2'>Disetujui</Typography>;
        } else if (salesOrderItem.status === ItemStatus.REJECT) {
          return <Typography variant='body2'>Ditolak</Typography>;
        } else if (salesOrderItem.status === ItemStatus.HAS_INVOICE) {
          return (
            <Typography variant='body2' style={{ color: BLACK }}>
              <Button variant='text' onClick={() => handleInvoice((salesOrderItem.invoice && salesOrderItem.invoice.id) || 0)}>
                Lihat Invoice
              </Button>
            </Typography>
          );
        } else {
          return <Typography variant='body2'>Menunggu</Typography>;
        }
      }
    } else if (tab === 1 && isConvertAllowed) {
      return (
        salesOrder.statusOrder !== 'CONFIRMATION' && (
          <Button
            disabled={currentWareHouse !== '' && warehouse !== currentWareHouse}
            variant='text'
            size='small'
            onClick={handleReject}
            className={classes.reject}
          >
            Tolak Barang
          </Button>
        )
      );
    } else {
      return (
        isConvertAllowed &&
        salesOrder.statusOrder !== 'CONFIRMATION' && (
          <Button
            disabled={currentWareHouse !== '' && warehouse !== currentWareHouse}
            variant='text'
            size='small'
            onClick={handleConfirm}
            className={classes.confirm}
          >
            Setujui Barang
          </Button>
        )
      );
    }
  };

  const getStatus = () => {
    if (isAccess === TypeUser.PICKER) {
      return salesOrderItem.status === SalesOrderStatus.PREVIEW
        ? statusConditaion.accept
        : salesOrderItem.status === SalesOrderStatus.REJECT
        ? statusConditaion.reject
        : '';
    } else if (isAccess === TypeUser.ADMIN01) {
      return salesOrderItem.status === SalesOrderStatus.PACKING
        ? statusConditaion.accept
        : salesOrderItem.status === SalesOrderStatus.REJECT
        ? statusConditaion.reject
        : '';
    }
  };

  return isLoading ? (
    <Grid xl={12} lg={12} style={{ marginBottom: '2em' }}>
      <Skeleton variant='text' height={25} component='div' />
      <Skeleton variant='text' height={25} component='div' />
    </Grid>
  ) : (
    <Fragment>
      {isAccess === TypeUser.SALES || isAccess === TypeUser.SUPERVISOR || isAccess === TypeUser.ADMIN || isAccess === TypeUser.ACCOUNTING ? (
        <Fragment>
          <TableRow
            className={clx(
              currentWareHouse !== '' && warehouse === currentWareHouse && classes.highlight,
              salesOrderItem.status === ItemStatus.HAS_INVOICE && classes.done
            )}
          >
            <TableCellStart style={{ minWidth: 20 }}>
              <IconButton aria-label='expand row' size='small' onClick={handleOpenCollapse}>
                {openCollapse && index === indexCollapse ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </TableCellStart>

            <TableCellMiddle>
              {isLoading ? (
                <Skeleton variant='text' width={100} height={25} />
              ) : (
                <Button variant='text' onClick={handleOpenTooltip}>
                  {(salesOrderItem && salesOrderItem.productName) || ''}
                </Button>
              )}
            </TableCellMiddle>

            <TableCellMiddle align='right'>
              {isLoading ? (
                <Skeleton variant='text' width={100} height={25} />
              ) : (
                <>
                  <NumberFormat value={salesOrderItem.price} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                  {isConvertAllowed && productPrice && currentPrice !== productPrice.price && (
                    <Tooltip
                      open={openAlertPrice && indexAlertPrice == index}
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      placement='bottom'
                      title={
                        <>
                          <Table size='small' className={classes.table}>
                            <TableBody>
                              <TableRow>
                                <TableCell>Harga Jual Rute</TableCell>
                                <TableCell>
                                  <NumberFormat
                                    value={productPrice ? productPrice.price : 0}
                                    prefix={'Rp'}
                                    thousandSeparator={true}
                                    displayType='text'
                                  />
                                </TableCell>
                              </TableRow>

                              <TableRow>
                                <TableCell>Harga Ditawarkan</TableCell>
                                <TableCell>
                                  <NumberFormat value={salesOrderItem.price} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                                </TableCell>
                              </TableRow>

                              <TableRow>
                                <TableCell>Ket</TableCell>
                                <TableCell>
                                  {productPrice &&
                                    currentPrice < productPrice.price &&
                                    'Harga yang ditawarkan dibawah dari harga jual rute saat ini.'}
                                  {productPrice && currentPrice > productPrice.price && 'Harga yang ditawarkan diatas dari harga jual rute saat ini.'}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </>
                      }
                    >
                      <IconButton onClick={handleOpenAlertPrice} size='small'>
                        <ErrorOutlineIcon fontSize='small' color='error' className={classes.iconPrice} />
                      </IconButton>
                    </Tooltip>
                  )}
                </>
              )}
            </TableCellMiddle>

            <TableCellMiddle align='center'>
              {isLoading ? <Skeleton variant='text' width={100} height={25} /> : salesOrderItem.totalItem + ' ' + salesOrderItem.typeUnit}
            </TableCellMiddle>

            <TableCellMiddle align='center'>
              {isLoading ? <Skeleton variant='text' width={100} height={25} /> : salesOrderItem.warehouse && salesOrderItem.warehouse}
            </TableCellMiddle>

            <TableCellMiddle align='right'>
              {isLoading ? (
                <Skeleton variant='text' width={100} height={25} />
              ) : (
                <NumberFormat
                  value={salesOrderItem.discount}
                  prefix={'Rp'}
                  thousandSeparator={true}
                  displayType='text'
                  className={clx(salesOrderItem.discount > 0 && classes.discount)}
                />
              )}
            </TableCellMiddle>

            <TableCellMiddle align='right'>
              {isLoading ? (
                <Skeleton variant='text' width={100} height={25} />
              ) : (
                <>
                  <NumberFormat value={salesOrderItem.totalPrice} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                  {isConvertAllowed && totalProductPrice !== salesOrderItem.totalPrice && (
                    <Tooltip
                      open={openAlertDiscount && indexAlertDiscount == index}
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      placement='bottom'
                      title={
                        <>
                          <Table size='small' className={classes.table}>
                            <TableBody>
                              <TableRow>
                                <TableCell>
                                  <Typography>Total Harga Jual Rute</Typography>
                                  <NumberFormat value={totalProductPrice} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                                </TableCell>
                              </TableRow>

                              <TableRow>
                                <TableCell>
                                  <Typography>Total Harga</Typography>
                                  <NumberFormat value={salesOrderItem.totalPrice} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                                </TableCell>
                              </TableRow>

                              <TableRow>
                                <TableCell>
                                  {totalProductPrice > salesOrderItem.totalPrice &&
                                    'Total Harga yang ditawarkan dibawah dari Total harga jual rute saat ini.'}
                                  {totalProductPrice < salesOrderItem.totalPrice &&
                                    'Total Harga yang ditawarkan diatas dari Total harga jual rute saat ini.'}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </>
                      }
                    >
                      <IconButton onClick={handleOpenAlertDiscount} size='small'>
                        <ErrorOutlineIcon fontSize='small' color='error' className={classes.iconPrice} />
                      </IconButton>
                    </Tooltip>
                  )}
                </>
              )}
            </TableCellMiddle>

            <TableCellEnd align='center'>{isLoading ? <Skeleton variant='text' width={100} height={25} /> : handleAction()}</TableCellEnd>
          </TableRow>

          <TableRowCollapse open={openCollapse && index === indexCollapse} colSpan={8}>
            <Table size='small'>
              <TableBody>
                <TableRow>
                  <TableCell colSpan={2}>Catatan</TableCell>
                  <TableCell colSpan={6} align='right'>
                    {(salesOrderItem.notes && salesOrderItem.notes) || 'Tidak ada catatan'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={2}>Total Stok</TableCell>
                  <TableCell colSpan={6} align='right'>
                    {((salesOrderItem && salesOrderItem.salesStock) || 0) +
                      ((salesOrderItem && salesOrderItem.holdStock) || 0) +
                      ' ' +
                      salesOrderItem.typeUnit}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell colSpan={2}>Total Stok Tertahan</TableCell>
                  <TableCell colSpan={6} align='right'>
                    {salesOrderItem.holdStock + ' ' + salesOrderItem.typeUnit}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>

            {salesOrderItem.Product && salesOrderItem.Product.isProductPackage && (
              <Table size='small'>
                <TableHead>
                  <TableRow>
                    <TableCell>Nama</TableCell>
                    <TableCell align='center'>Kuantitas</TableCell>
                    <TableCell align='center'>Bonus Kuantitas</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {salesOrderItem.Product &&
                    salesOrderItem.Product.ProductPackage &&
                    salesOrderItem.Product.ProductPackage.ProductItem &&
                    salesOrderItem.Product.ProductPackage.ProductItem.length > 0 &&
                    salesOrderItem.Product.ProductPackage.ProductItem.map((value, index) => (
                      <TableRow key={index}>
                        <TableCell className={classes.cellCollapse}>{value.Product ? value.Product.productName : '-'}</TableCell>
                        <TableCell align='center' className={classes.cellCollapse}>{`${value.minimumItem || 0}/${
                          value.Product ? value.Product.typeUnit : ''
                        }`}</TableCell>
                        <TableCell align='center' className={classes.cellCollapse}>
                          {`${value.bonusItem || 0}/${value.Product ? value.Product.typeUnit : ''}`}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            )}
          </TableRowCollapse>
        </Fragment>
      ) : (
        <Grid xs={12} xl={12} container className={classes.bodyContainer}>
          {isAccess === TypeUser.SUPERADMIN && (
            <Fragment>
              {salesOrderItem.notes && salesOrderItem.notes && (
                <Tooltip
                  classes={{ tooltip: classes.descriptionContainer }}
                  placement='right-end'
                  title={
                    <Grid>
                      <Typography variant='h6' color='textSecondary'>
                        Catatan:
                      </Typography>
                      <Typography align='justify' className={classes.description}>
                        {(salesOrderItem.notes && salesOrderItem.notes) || 'Tidak ada catatan'}
                      </Typography>
                    </Grid>
                  }
                >
                  <InfoRounded className={classes.warning} />
                </Tooltip>
              )}
              <BodyItem
                imageUrl={salesOrderItem.productImage || 'noimage'}
                title={salesOrderItem.productName}
                isWidth='0.31'
                handleOpenImage={handleOpenImage}
              />

              <BodyItem title={`Rp${new Intl.NumberFormat('id-ID').format(salesOrderItem.price)}`} isWidth='0.13' />
              <BodyItem
                title={salesOrderItem.totalItem.toString().concat(` ${salesOrderItem.typeUnit}`)}
                isWidth='0.12'
                isUpdateQty={salesOrder.isPlafon}
                isQty={true}
                isAccess={isAccess}
                onClickEdit={handleOpenEditQty}
                hasStatus={salesOrderItem.status}
              />
              <BodyItem
                title={salesOrderItem.discount > 0 ? `Rp${new Intl.NumberFormat('id-ID').format(salesOrderItem.discount)}` : '-'}
                isDiscount={true}
                onClick={handleOpenModal}
                isWidth='0.17'
                isAccess={isAccess}
                isEditDiscount={salesOrderItem.status === SalesOrderStatus.REJECT || salesOrderItem.status === SalesOrderStatus.HAS_INVOICE}
              />
              <BodyItem title={`Rp${new Intl.NumberFormat('id-ID').format(salesOrderItem.totalPrice)}`} isWidth='0.16' />
              <BodyItem title={salesOrderItem.warehouse} isWidth='0.12' />
              <BodyItem
                isReject={true}
                hasAction={salesOrderItem.discount < 1}
                isWidth='0.12'
                hasStatus={salesOrderItem.status}
                isAccess={isAccess}
                handleRejectAdmin={handleRejectSuper}
                tab={tab}
                handleCancelRejectAdmin={handleCancelRejectSuper}
                loadingReject={loadingReject}
                handleInvoice={() => handleInvoice((salesOrderItem.invoice && salesOrderItem.invoice.id) || 0)}
                isLoadingCancel={isEditReject === salesOrderItem.ProductId}
                isLoadingDiskon={salesOrderItem.status !== SalesOrderStatus.REJECT && isEditReject === salesOrderItem.ProductId}
              />
            </Fragment>
          )}

          {isAccess === TypeUser.PICKER && (
            <Fragment>
              <BodyItem imageUrl={salesOrderItem.productImage} title={salesOrderItem.productName} isWidth='0.45' handleOpenImage={handleOpenImage} />

              <BodyItem
                title={salesOrderItem.totalItem.toString().concat(` ${salesOrderItem.typeUnit}`)}
                isWidth='0.23'
                newQty={newQty}
                setNewQty={setNewQty}
                qtyStatus='100'
                isEdit={isEdit}
                isChecking={newQty > ((salesOrderItem && salesOrderItem.salesStock) || 0)}
              />
              <BodyItem title={salesOrderItem.warehouse} isWidth='0.23' />
              <BodyItem
                isWidth='0.15'
                isAccess={isAccess}
                onClick={handleOpenEdit}
                onClickEdit={handleEdit}
                isAction={isEdit}
                isChecking={newQty > ((salesOrderItem && salesOrderItem.salesStock) || 0)}
                UpdateItemQty={UpdateItemQty}
                handleAccept={handleAccept}
                setAction={handleIsAction}
                tab={tab}
                status={salesOrderItem.status !== SalesOrderStatus.PENDING}
                hasStatus={getStatus()}
                handleRejectItem={handleRejectItemAction}
              />
            </Fragment>
          )}

          {isAccess === TypeUser.ADMIN01 && (
            <Fragment>
              <BodyItem imageUrl={salesOrderItem.productImage} title={salesOrderItem.productName} isWidth='0.45' handleOpenImage={handleOpenImage} />
              <BodyItem
                title={salesOrderItem.totalItem.toString().concat(` ${salesOrderItem.typeUnit}`)}
                isWidth='0.23'
                newQty={newQty}
                setNewQty={setNewQty}
                qtyStatus='100'
                isEdit={isEdit}
                isChecking={newQty > ((salesOrderItem && salesOrderItem.salesStock) || 0)}
              />
              <BodyItem title={salesOrderItem.warehouse} isWidth='0.23' />
              <BodyItem
                isWidth='0.15'
                isAccess={isAccess}
                onClick={handleOpenEdit}
                onClickEdit={handleEdit}
                isAction={isEdit}
                isChecking={newQty > ((salesOrderItem && salesOrderItem.salesStock) || 0)}
                UpdateItemQty={UpdateItemQty}
                handleAccept={handleAccept}
                setAction={handleIsAction}
                tab={tab}
                status={salesOrderItem.status !== SalesOrderStatus.PREVIEW}
                hasStatus={getStatus()}
                handleRejectItem={handleRejectItemAction}
              />
            </Fragment>
          )}

          {(isAccess === TypeUser.ADMIN02 || isAccess === TypeUser.ADMIN02PLUS) && (
            <Fragment>
              <BodyItem
                imageUrl={salesOrderItem.productImage}
                title={salesOrderItem.productName}
                isWidth='0.45'
                isColly={salesOrderItem.totalInBag !== salesOrderItem.totalItem ? true : false}
                isCheckList={true}
                checked={checked}
                valueCheckList={salesOrderItem.ProductId}
                handleChange={handleChange}
                handleOpenImage={handleOpenImage}
              />

              <BodyItem
                title={salesOrderItem.totalItem.toString().concat(` ${salesOrderItem.typeUnit}`)}
                isWidth='0.24'
                isEdit={isEdit}
                typeUnit={salesOrderItem.typeUnit}
                isChecking={newQty > ((salesOrderItem && salesOrderItem.salesStock) || 0)}
                newQty={newQty}
                setNewQty={setNewQty}
              />
              <BodyItem title={salesOrderItem.warehouse} isWidth='0.25' />
              <BodyItem
                isWidth='0.13'
                isAccess={isAccess}
                onClick={handleOpenEdit}
                onClickEdit={handleEdit}
                isColly={salesOrderItem.totalInBag !== salesOrderItem.totalItem ? true : false}
                isAction={isEdit}
                isChecking={newQty > ((salesOrderItem && salesOrderItem.salesStock) || 0)}
                UpdateItemQty={UpdateItemQty}
                handleAccept={handleAccept}
                setAction={handleIsAction}
              />
            </Fragment>
          )}
        </Grid>
      )}
    </Fragment>
  );
};

export default BodyRow;
