import React, { FC, useContext, useEffect } from 'react';
import {
  Grid,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TextField,
  TableContainer,
  makeStyles,
  MenuItem,
  ListSubheader,
  OutlinedInput,
  Select,
  Checkbox,
  ListItemText,
  FormControl
} from '@material-ui/core';
import BodyRow from './components/BodyRow';
import { HeaderRow, TableCellHead, TableCustom } from 'components/Table';
import { dummyInvoice } from 'utils/dummy';
import { PaperCustom, StatusPayment } from 'components';
import { Pagination } from '@material-ui/lab';
import useRole from 'hooks/useRole';
import { CurrentUserContext } from 'contexts/CurrentUserContext';

interface Props {
  isLoadingData: boolean;
  count: number;
  currentPage: number;
  invoices: InvoiceModel[];
  order: 'asc' | 'desc';
  orderBy: string;
  indexCollapse: number;
  openCollapse: boolean;
  collapseLoading: boolean;
  handleClose?: () => void;
  statusPayment: string;
  statusReturn: string;

  invoiceId: string;
  setStatusReturn: React.Dispatch<React.SetStateAction<string>>;
  setStatusPayment: React.Dispatch<React.SetStateAction<string>>;
  setInvoiceId: React.Dispatch<React.SetStateAction<string>>;
  setOrder: React.Dispatch<React.SetStateAction<'asc' | 'desc'>>;
  setOrderBy: React.Dispatch<React.SetStateAction<string>>;
  handleOpenCollapse: (index: number, id: number, type?: string) => React.MouseEventHandler;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
}

const useStyles = makeStyles({
  cellStartId: {
    width: '10%'
  },
  cellTable3: {
    width: '13%'
  },
  cellStatus: {
    width: '200px'
  }
});

const InvoiceTable: FC<Props> = props => {
  const { currentUser } = useContext(CurrentUserContext);

  const classes = useStyles();
  const {
    isLoadingData,
    invoices,
    order,
    orderBy,
    count,
    indexCollapse,
    openCollapse,
    invoiceId,
    handleClose,
    statusPayment,
    statusReturn,
    collapseLoading,
    currentPage,
    setStatusPayment,
    setStatusReturn,
    setInvoiceId,
    setOrder,
    setOrderBy,
    handleOpenCollapse,
    setCurrentPage
  } = props;

  const handleRequestSort = ({}, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const isSuperVisor = useRole({
    type: (currentUser && currentUser.type) || 'SUPERVISOR',
    allowed: ['SUPERVISOR']
  });

  return (
    <PaperCustom>
      <TableContainer>
        <TableCustom>
          <TableHead>
            <HeaderRow
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headers={[
                { label: '#' },
                { id: 'id', label: 'ID', sort: true },
                { id: 'statusPayment', label: 'STATUS', sort: true },
                { id: 'dueDate', label: 'TGL INVOICE', sort: true },
                { id: 'dueDate', label: 'TGL BATAS PELUNASAN', sort: true },
                { id: 'totalPrice', label: 'TOTAL TAGIHAN', sort: true },
                { id: 'totalPrice', label: 'SISA TAGIHAN', sort: true },
                { label: '' }
              ]}
            />
            <TableRow>
              <TableCellHead variant='head' className={classes.cellStartId} colSpan={2}>
                <TextField id='invoiceId' value={invoiceId} onChange={event => setInvoiceId(event.target.value)} />
              </TableCellHead>
              <TableCellHead variant='head' className={classes.cellTable3}>
                <FormControl
                  size='small'
                  style={{
                    margin: 1
                  }}
                >
                  <Select
                    value={[statusReturn, statusPayment]}
                    onChange={event => {
                      const lastIndex = (event.target.value as string[]).length - 1 || 0;

                      setStatusReturn(prev => {
                        if (
                          ['Belum dipotong', 'Potong Keseluruhan', 'Potong Sebagian'].some(val => (event.target.value as string[])[lastIndex] === val)
                        ) {
                          return (event.target.value as string[])[lastIndex];
                        }
                        if ((event.target.value as string[]).some(v => v === prev)) {
                          return prev;
                        }
                        return '';
                      });

                      setStatusPayment(prev => {
                        if (['PAID', 'UNPAID', 'CANCEL'].some(val => (event.target.value as string[])[lastIndex] === val)) {
                          return (event.target.value as string[])[lastIndex];
                        }
                        if ((event.target.value as string[]).some(v => v === prev)) {
                          return prev;
                        }
                        return '';
                      });
                    }}
                    multiple
                    input={<OutlinedInput fullWidth />}
                    fullWidth
                    className={classes.cellStatus}
                    renderValue={selected => {
                      return (selected as string[])?.every(Boolean) ? (selected as string[]).join(', ') : (selected as string[]).join('');
                    }}
                  >
                    <MenuItem value='' disabled>
                      Pilih Status
                    </MenuItem>
                    <ListSubheader>Status Return</ListSubheader>
                    <MenuItem value='Belum dipotong'>
                      <Checkbox checked={statusReturn === 'Belum dipotong'} color='primary' />
                      <ListItemText primary='Belum dipotong' />
                    </MenuItem>
                    <MenuItem value='Potong Keseluruhan'>
                      <Checkbox checked={statusReturn === 'Potong Keseluruhan'} color='primary' />
                      <ListItemText primary='Potong Keseluruhan' />
                    </MenuItem>
                    <MenuItem value='Potong Sebagian'>
                      <Checkbox checked={statusReturn === 'Potong Sebagian'} color='primary' />
                      <ListItemText primary='Potong Sebagian' />
                    </MenuItem>
                    <ListSubheader>Status Invoice</ListSubheader>

                    <MenuItem key={1} value='PAID'>
                      <Checkbox checked={statusPayment === 'PAID'} color='primary' />
                      <ListItemText primary='Lunas' />
                    </MenuItem>
                    <MenuItem key={2} value='UNPAID'>
                      <Checkbox checked={statusPayment === 'UNPAID'} color='primary' />
                      <ListItemText primary='Belum Lunas' />
                    </MenuItem>
                    {!isSuperVisor && (
                      <MenuItem key={4} value='CANCEL'>
                        <Checkbox checked={statusPayment === 'CANCEL'} color='primary' />
                        <ListItemText primary='Batal' />
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
              </TableCellHead>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoadingData ? (
              [0, 1, 2, 3].map(index => (
                <BodyRow
                  key={index}
                  index={index}
                  indexCollapse={indexCollapse}
                  openCollapse={openCollapse}
                  collapseLoading={collapseLoading}
                  isLoading={isLoadingData}
                  invoice={dummyInvoice}
                  handleOpenCollapse={handleOpenCollapse(index, 0)}
                />
              ))
            ) : invoices.length > 0 ? (
              invoices.map((value, index) => (
                <BodyRow
                  key={index}
                  index={index}
                  handleClose={handleClose}
                  indexCollapse={indexCollapse}
                  openCollapse={openCollapse}
                  collapseLoading={collapseLoading}
                  isLoading={isLoadingData}
                  invoice={value}
                  handleOpenCollapse={handleOpenCollapse(index, value.id, value.type)}
                />
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6} align='center'>
                  Data Tidak Tersedia.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </TableCustom>
      </TableContainer>

      <Grid container justify='center' item xl={12} md={12} sm={12}>
        <Grid item>
          {invoices.length > 0 && (
            <Pagination count={count} onChange={(event, page) => setCurrentPage(page)} page={currentPage} boundaryCount={2} variant='outlined' />
          )}
        </Grid>
      </Grid>
    </PaperCustom>
  );
};

export default InvoiceTable;
