import React, { FC } from 'react';
import { TableBody, TableHead, TableRow, TableCell, TextField, TableContainer, makeStyles, MenuItem } from '@material-ui/core';
import BodyRow from './component/BodyRow';
import { HeaderRow, TableCellHead, TableCustom } from 'components/Table';
import { dummyPurchaseInvoice } from 'utils/dummy';
import { PaperCustom } from 'components';

interface Props {
  isLoadingData: boolean;
  count: number;
  currentPage: number;
  purchaseInvoices?: PurchaseInvoiceModel[];
  order: 'asc' | 'desc';
  orderBy: string;
  indexCollapse: number;
  openCollapse: boolean;
  statusPayment: string;
  purchaseInvoiceId: string;
  collapseLoading: boolean;
  statusInvoice: string;
  setStatusInvoice: React.Dispatch<React.SetStateAction<string>>;
  setStatusPayment: React.Dispatch<React.SetStateAction<string>>;
  setInvoiceId: React.Dispatch<React.SetStateAction<string>>;
  setOrder: React.Dispatch<React.SetStateAction<'asc' | 'desc'>>;
  setOrderBy: React.Dispatch<React.SetStateAction<string>>;

  handleOpenCollapse: (id: number, type?: string) => React.MouseEventHandler;
}

const useStyles = makeStyles({
  cellStartId: {
    width: '10%'
  },
  cellTable: {
    width: '17%'
  },
  cellTable3: {
    width: '13%'
  }
});

const PurchaseInvoiceTable: FC<Props> = props => {
  const classes = useStyles();
  const {
    isLoadingData,
    purchaseInvoices,
    order,
    orderBy,
    indexCollapse,
    openCollapse,

    purchaseInvoiceId,
    collapseLoading,

    setInvoiceId,
    setOrder,
    setOrderBy,

    handleOpenCollapse,
    statusInvoice,
    setStatusInvoice
  } = props;

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <PaperCustom>
      <TableContainer>
        <TableCustom>
          <TableHead>
            <HeaderRow
              key={1}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headers={[
                { label: '#' },
                { id: 'invoiceNumber', label: 'Nomor Invoice', sort: true },
                { id: 'totalPrice', label: 'Total Harga', sort: true, align: 'right' },
                { id: 'totalItem', label: 'Total Item', sort: true, align: 'center' },
                { id: 'dueDate', label: 'JATUH TEMPO', sort: true }
              ]}
            />
            <TableRow key={2}>
              <TableCellHead variant='head' className={classes.cellStartId} colSpan={2}>
                <TextField
                  placeholder='No Invoice'
                  id='purchaseInvoiceId'
                  value={purchaseInvoiceId}
                  onChange={event => setInvoiceId(event.target.value)}
                />
              </TableCellHead>

              <TableCellHead align='center' variant='head' />
              <TableCellHead align='center' variant='head' />
              <TableCellHead align='center' variant='head' />
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoadingData ? (
              [0, 1, 2, 3].map(index => (
                <BodyRow
                  key={index}
                  index={index}
                  indexCollapse={indexCollapse}
                  openCollapse={openCollapse}
                  isLoading={isLoadingData}
                  statusInvoice={statusInvoice}
                  purchaseInvoice={dummyPurchaseInvoice}
                  collapseLoading={collapseLoading}
                  handleOpenCollapse={handleOpenCollapse(index)}
                />
              ))
            ) : purchaseInvoices && purchaseInvoices.length > 0 ? (
              purchaseInvoices.map((value, index) => (
                <BodyRow
                  key={index}
                  index={value.id}
                  indexCollapse={indexCollapse}
                  openCollapse={openCollapse}
                  isLoading={isLoadingData}
                  purchaseInvoice={value}
                  statusInvoice={statusInvoice}
                  collapseLoading={collapseLoading}
                  handleOpenCollapse={handleOpenCollapse(value.id, value.type)}
                />
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={7} align='center'>
                  Data Tidak Tersedia.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </TableCustom>
      </TableContainer>
    </PaperCustom>
  );
};

export default PurchaseInvoiceTable;
