import React, { useState, useEffect } from 'react';
import axios from 'axios';
import clsx from 'clsx';
import { Theme, makeStyles } from '@material-ui/core';
import { Switch, Route } from 'react-router';
import SettingsPage from 'pages/SettingsPage';
import UserPage from 'pages/UserPage';
import AppLogPage from 'pages/AppLogPage';
import AccessPage from 'pages/AccessPage';
import ConditionalRoute from 'components/ConditionalRoute';
import LoginPage from 'pages/LoginPage';
import HomePage from 'pages/HomePage';
import NotFoundPage from 'pages/NotFoundPage';
import CompanyPage from 'pages/CompanyPage';
import PartnerPage from 'pages/PartnerPage';
import CategoryPage from 'pages/CategoryPage';
import ProductPage from 'pages/ProductPage';
import ProductDetailPage from 'pages/ProductDetailPage';
import ProductPackageDetailPage from 'pages/ProductPackageDetailPage';
import PartnerDetailPage from 'pages/PartnerDetailPage';
import CommissionPage from 'pages/CommissionPage';
import CommissionDetailPage from 'pages/CommissionDetail';
import CommissionCreatePage from 'pages/CommissionCreatePage';
import AppHeader from 'components/AppHeader';
import AppDrawer from 'components/AppDrawer';
import WareHousePage from 'pages/WareHousePage';
import ZonePage from 'pages/ZonePage';
import PurchaseOrderPage from 'pages/PurchaseOrderPage';
import PurchaseOrderDetailPage from 'pages/PurchaseOrderDetailPage';
import PurchaseOrderCreatePage from 'pages/PurchaseOrderCreatePage';
import StockInPage from 'pages/StockInPage';
import AllStockPage from 'pages/AllStockPage';
import StockInDetailPage from 'pages/StockInDetailPage';
import StockOutDetailPage from 'pages/StockOutDetailPage';
import StockOutPage from 'pages/StockOutPage';
import SalesOrderDetailPage from 'pages/SalesOrderDetailPage';
import SalesOrderPage from 'pages/SalesOrderPage';
import SalesOrderCreatePage from 'pages/SalesOrderCreatePage';
import TransferPage from 'pages/TransferPage';
import ReturnOrderPage from 'pages/ReturnOrderPage';
import ReturnOrderCreatePage from 'pages/ReturnOrderCreatePage';
import ReturnDetailPage from 'pages/ReturnDetailPage';
import InvoicePage from 'pages/InvoicePage';
import InvoiceCreatePage from 'pages/InvoiceCreatePage';
import PurchaseInvoicePage from 'pages/PurchaseInvoicePage';
import PurchaseInvoiceCreatePage from 'pages/PurchaseInvoiceCreatePage';
import InvoiceDetailPage from 'pages/InvoiceDetailPage';
import PurchaseInvoiceDetailPage from 'pages/PurchaseInvoiceDetailPage';
import PaymentPage from 'pages/PaymentPage';
import PaymentDetailPage from 'pages/PaymentDetailPage';
import PaymentCreatePage from './pages/PaymentCreatePage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import ResetPasswordPage from 'pages/ResetPassowordPage';
import StockCheckPage from 'pages/StockCheckPage';
import StockCheckCreatePage from 'pages/StockCheckCreatePage';
import StockCheckDetailPage from 'pages/StockCheckDetailPage';
import FinancePage from 'pages/FinancePage';
import ReviewStock from 'pages/ReviewStock';
import ReviewStockDetail from 'pages/ReviewStockDetail';
import { CurrentUserProvider } from 'contexts/CurrentUserContext';
import { CartProvider } from 'contexts/CartContext';
import { isUserAuthenticated } from 'selectors';
import { attachTokenToHeader, detachTokenFromHeader } from 'utils/AxiosUtils';
import { GET_CURRENT_USER_URL, BADGE_NOTIFICATION_BASE_URL, INVOICE_BASE_URL, DELIVERY_ORDER_NOTIF } from 'constants/url';
import 'react-quill/dist/quill.snow.css';
import TypeUser from 'typings/enum/TypeUser';
import UserDetailPage from 'pages/UserDetail';
import ProductDamagePage from 'pages/ProductDamagePage';
import DeliveryOrder from 'pages/DeliveryOrder';
import DeliveryOrderCreate from 'pages/DeliveryOrderCreate';
import DeliveryOrderDetail from 'pages/DeliveryOrderDetail';
import Dashboard from 'pages/DashboardPage';
import StockMovementPage from 'pages/StockMovementPage';
import ReturnBillPage from 'pages/ReturnBillPage';
import ReturnBillDetailPage from 'pages/ReturnBillDetailPage';
import PurchasePaymentPage from 'pages/PurchasePaymentPage';
import PurchasePaymentCreatePage from 'pages/PurchasePaymentCreatePage';
import PurchasePaymentDetailPage from 'pages/PurchasePaymentDetailPage';

const { REACT_APP_DRAWER_WIDTH = '240' } = process.env;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex'
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  contentMarginSM: {
    marginLeft: '72px'
  },
  contentSpace: {
    [theme.breakpoints.between('md', 'xl')]: {
      marginLeft: +REACT_APP_DRAWER_WIDTH
    }
  }
}));

const App: React.FC = () => {
  const classes = useStyles();

  const [CurrentUserData, setCurrentUserData] = useState<CurrentUser>();
  const [isAuthenticating, setAuthenticating] = useState(true);
  const [openDrawer, setOpenDrawer] = useState(true);
  const [openDrawerMobile, setOpenDrawerMobile] = useState<boolean>(false);
  const [carts, setCarts] = useState<CartModel | null>(null);
  const [totalSalesOrder, setTotalSalesOrder] = useState<number>(0);
  const [totalRequesEdit, setTotalRequestEdit] = useState<number>(0);
  const [totalNewEdit, setTotalNewEdit] = useState<number>(0);
  const [totalNotifSJ, setTotalNotifSJ] = useState<number>(0);

  const isLoggedIn = isUserAuthenticated(CurrentUserData);

  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };

  const handleDrawerOpenMobile = () => {
    setOpenDrawerMobile(true);
  };

  const handleDrawerCloseMobile = () => {
    setOpenDrawerMobile(false);
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  const setCurrentUser = (currentUser: CurrentUser, token: string): void => {
    localStorage.setItem('token', token);
    attachTokenToHeader(token);
    setCurrentUserData(currentUser);
  };

  const unsetCurrentUser = (): void => {
    localStorage.removeItem('token');
    detachTokenFromHeader();

    setCurrentUserData(undefined);
  };

  useEffect(() => {
    const getPersistedToken = () => {
      return localStorage.getItem('token');
    };

    const getCurrentUserData = async () => {
      setAuthenticating(true);

      const token = getPersistedToken();
      if (token) {
        try {
          const response = await axios.get(GET_CURRENT_USER_URL, { headers: { Authorization: `Bearer ${token}` } });
          const data: CurrentUser = response.data.data;

          setCurrentUser(data, token);
        } catch (err) {
          unsetCurrentUser();
        }
      }
      setAuthenticating(false);
    };
    getCurrentUserData();
  }, []);

  const setCart = (cart: CartModel | null): void => {
    setCarts(cart);
  };

  useEffect(() => {
    if (!CurrentUserData) {
      return;
    }

    if (CurrentUserData.type === 'SALES') {
      return;
    }

    const getPersistedToken = () => {
      return localStorage.getItem('token');
    };

    const notif = async () => {
      const token = getPersistedToken();
      if (token) {
        try {
          const { data } = await axios.get(BADGE_NOTIFICATION_BASE_URL, { headers: { Authorization: `Bearer ${token}` } });
          setTotalSalesOrder(data.data.totalSalesOrder);
        } catch (error) {
          console.log('badge error:', error);
        }
      }
    };

    const fecthTotalNotifSJ = async () => {
      try {
        const { data } = await axios.get(`${DELIVERY_ORDER_NOTIF}`);
        setTotalNotifSJ(data.data.total);
      } catch (error) {
        console.log('error :', error);
      }
    };

    const requestEdit = async () => {
      const token = getPersistedToken();
      if (token) {
        try {
          const { data } = await axios.get(`${INVOICE_BASE_URL}/requestall`, { headers: { Authorization: `Bearer ${token}` } });
          setTotalRequestEdit(data.total);
        } catch (error) {
          console.log('badge error:', error);
        }
      }
    };

    const requestNewEdit = async () => {
      const token = getPersistedToken();
      if (token) {
        try {
          const { data } = await axios.get(`${INVOICE_BASE_URL}/new-edit`, { headers: { Authorization: `Bearer ${token}` } });
          setTotalNewEdit(data.total);
        } catch (error) {
          console.log('badge error:', error);
        }
      }
    };

    notif();
    requestEdit();
    requestNewEdit();
    fecthTotalNotifSJ();
  }, [CurrentUserData]);

  const redirectUrl = () => {
    const isAccess = !!CurrentUserData && CurrentUserData.type;
    if (
      isAccess === TypeUser.PICKER ||
      isAccess === TypeUser.ADMIN01 ||
      isAccess === TypeUser.ADMIN ||
      isAccess === TypeUser.ADMIN02 ||
      isAccess === TypeUser.ADMIN02PLUS ||
      isAccess === TypeUser.SALES ||
      isAccess === TypeUser.SUPERADMIN ||
      isAccess === TypeUser.SUPERVISOR ||
      isAccess === TypeUser.ACCOUNTING
    ) {
      return '/penjualan';
    } else if (isAccess === TypeUser.ADMIN03) {
      return '/invoice';
    } else if (isAccess === TypeUser.HR) {
      return '/dashboard';
    } else if (isAccess === TypeUser.ADMIN04 || isAccess === TypeUser.BUYER) {
      return '/mitra';
    } else {
      return '/return-penjualan';
    }
  };
  return isAuthenticating ? null : (
    <CurrentUserProvider
      value={{
        currentUser: CurrentUserData,
        setCurrentUser,
        unsetCurrentUser
      }}
    >
      <CartProvider
        value={{
          cart: carts,
          setCart
        }}
      >
        <div className={classes.root}>
          {isLoggedIn && (
            <nav>
              <AppHeader
                open={openDrawer}
                handleDrawerOpen={handleDrawerOpen}
                openMobile={openDrawerMobile}
                handleDrawerOpenMobile={handleDrawerOpenMobile}
              />
              <AppDrawer
                openDrawer={openDrawer}
                currentUserData={CurrentUserData}
                handleDrawerClose={handleDrawerClose}
                openMobile={openDrawerMobile}
                handleDrawerCloseMobile={handleDrawerCloseMobile}
                useNotification={totalSalesOrder === 0}
                totalNotification={totalSalesOrder}
                totalRequestEdit={totalRequesEdit}
                totalNewEdit={totalNewEdit}
                totalNotifSJ={totalNotifSJ}
              />
            </nav>
          )}

          <main className={clsx(classes.content, isLoggedIn && openDrawer && classes.contentSpace, !openDrawer && classes.contentMarginSM)}>
            {isLoggedIn && <div className={classes.appBarSpacer} />}
            <Switch>
              <ConditionalRoute exact={true} path={'/'} routeCondition={!isLoggedIn} component={LoginPage} redirectTo={redirectUrl()} />
              <ConditionalRoute exact={true} path={'/home'} routeCondition={isLoggedIn} component={HomePage} redirectTo={'/'} />
              <ConditionalRoute exact={true} path={'/dashboard'} routeCondition={isLoggedIn} component={Dashboard} redirectTo={'/'} />
              <ConditionalRoute exact={true} path={'/pengaturan'} routeCondition={isLoggedIn} component={SettingsPage} redirectTo={'/'} />
              <ConditionalRoute exact={true} path={'/users'} routeCondition={isLoggedIn} component={UserPage} redirectTo={'/'} />
              <ConditionalRoute exact={true} path={'/users/detail'} routeCondition={isLoggedIn} component={UserDetailPage} redirectTo={'/'} />
              <ConditionalRoute exact={true} path={'/access'} routeCondition={isLoggedIn} component={AccessPage} redirectTo={'/'} />
              <ConditionalRoute exact={true} path={'/perusahaan'} routeCondition={isLoggedIn} component={CompanyPage} redirectTo={'/'} />
              <ConditionalRoute exact={true} path={'/mitra'} routeCondition={isLoggedIn} component={PartnerPage} redirectTo={'/'} />
              <ConditionalRoute exact={true} path={'/mitra/:id'} routeCondition={isLoggedIn} component={PartnerDetailPage} redirectTo={'/'} />
              <ConditionalRoute exact={true} path={'/produk'} routeCondition={isLoggedIn} component={ProductPage} redirectTo={'/'} />
              <ConditionalRoute exact={true} path={'/produk/:id'} routeCondition={isLoggedIn} component={ProductDetailPage} redirectTo={'/'} />
              <ConditionalRoute exact={true} path={'/kategori'} routeCondition={isLoggedIn} component={CategoryPage} redirectTo={'/'} />
              <ConditionalRoute exact={true} path={'/komisi'} routeCondition={isLoggedIn} component={CommissionPage} redirectTo={'/'} />
              <ConditionalRoute exact={true} path={'/komisi/detail'} routeCondition={isLoggedIn} component={CommissionDetailPage} redirectTo={'/'} />
              <ConditionalRoute exact={true} path={'/komisi/tambah'} routeCondition={isLoggedIn} component={CommissionCreatePage} redirectTo={'/'} />
              <ConditionalRoute exact={true} path={'/gudang'} routeCondition={isLoggedIn} component={WareHousePage} redirectTo={'/'} />
              <ConditionalRoute exact={true} path={'/rute'} routeCondition={isLoggedIn} component={ZonePage} redirectTo={'/'} />
              <ConditionalRoute exact={true} path={'/purchase'} routeCondition={isLoggedIn} component={PurchaseOrderPage} redirectTo={'/'} />
              <ConditionalRoute exact={true} path={'/mutasi'} routeCondition={isLoggedIn} component={TransferPage} redirectTo={'/'} />
              <ConditionalRoute exact={true} path={'/invoice'} routeCondition={isLoggedIn} component={InvoicePage} redirectTo={'/'} />
              <ConditionalRoute exact={true} path={'/invoice/:id'} routeCondition={isLoggedIn} component={InvoiceDetailPage} redirectTo={'/'} />
              <ConditionalRoute exact={true} path={'/invoice/tambah'} routeCondition={isLoggedIn} component={InvoiceCreatePage} redirectTo={'/'} />
              <ConditionalRoute
                exact={true}
                path={'/invoice-pembelian'}
                routeCondition={isLoggedIn}
                component={PurchaseInvoicePage}
                redirectTo={'/'}
              />
              <ConditionalRoute exact={true} path={'/tagihan-return'} routeCondition={isLoggedIn} component={ReturnBillPage} redirectTo={'/'} />
              <ConditionalRoute
                exact={true}
                path={'/tagihan-return/:id'}
                routeCondition={isLoggedIn}
                component={ReturnBillDetailPage}
                redirectTo={'/'}
              />
              <ConditionalRoute
                exact={true}
                path={'/invoice-pembelian/detail'}
                routeCondition={isLoggedIn}
                component={PurchaseInvoiceDetailPage}
                redirectTo={'/'}
              />
              <ConditionalRoute
                exact={true}
                path={'/invoice-pembelian/tambah'}
                routeCondition={isLoggedIn}
                component={PurchaseInvoiceCreatePage}
                redirectTo={'/'}
              />

              <ConditionalRoute exact={true} path={'/surat-jalan'} routeCondition={isLoggedIn} component={DeliveryOrder} redirectTo={'/'} />
              <ConditionalRoute
                exact={true}
                path={'/surat-jalan/tambah'}
                routeCondition={isLoggedIn}
                component={DeliveryOrderCreate}
                redirectTo={'/'}
              />
              <ConditionalRoute exact={true} path={'/surat-jalan/:id'} routeCondition={isLoggedIn} component={DeliveryOrderDetail} redirectTo={'/'} />

              <ConditionalRoute
                exact={true}
                path={'/purchase/tambah'}
                routeCondition={isLoggedIn}
                component={PurchaseOrderCreatePage}
                redirectTo={'/'}
              />

              <ConditionalRoute
                exact={true}
                path={'/purchase/edit'}
                routeCondition={isLoggedIn}
                component={PurchaseOrderCreatePage}
                redirectTo={'/'}
              />

              <ConditionalRoute
                exact={true}
                path={'/purchase/:id'}
                routeCondition={isLoggedIn}
                component={PurchaseOrderDetailPage}
                redirectTo={'/'}
              />

              <ConditionalRoute exact={true} path={'/return/edit'} routeCondition={isLoggedIn} component={ReturnOrderCreatePage} redirectTo={'/'} />
              <ConditionalRoute exact={true} path={'/stok'} routeCondition={isLoggedIn} component={StockInPage} redirectTo={'/'} />
              <ConditionalRoute exact={true} path={'/semuastok'} routeCondition={isLoggedIn} component={AllStockPage} redirectTo={'/'} />
              <ConditionalRoute exact={true} path={'/stok/detail'} routeCondition={isLoggedIn} component={StockInDetailPage} redirectTo={'/'} />
              <ConditionalRoute exact={true} path={'/stokKeluar'} routeCondition={isLoggedIn} component={StockOutPage} redirectTo={'/'} />
              <ConditionalRoute
                exact={true}
                path={'/stokKeluar/detail'}
                routeCondition={isLoggedIn}
                component={StockOutDetailPage}
                redirectTo={'/'}
              />
              <ConditionalRoute exact={true} path={'/penjualan'} routeCondition={isLoggedIn} component={SalesOrderPage} redirectTo={'/'} />

              <ConditionalRoute
                exact={true}
                path={'/penjualan/tambah'}
                routeCondition={isLoggedIn}
                component={SalesOrderCreatePage}
                redirectTo={'/'}
              />

              <ConditionalRoute exact={true} path={'/penjualan/:id'} routeCondition={isLoggedIn} component={SalesOrderDetailPage} redirectTo={'/'} />
              <ConditionalRoute
                exact={true}
                path={'/paket/detail/:id'}
                routeCondition={isLoggedIn}
                component={ProductPackageDetailPage}
                redirectTo={'/'}
              />
              <ConditionalRoute exact={true} path={'/pembayaran-penjualan'} routeCondition={isLoggedIn} component={PaymentPage} redirectTo={'/'} />
              <ConditionalRoute
                exact={true}
                path={'/pembayaran-penjualan/tambah'}
                routeCondition={isLoggedIn}
                component={PaymentCreatePage}
                redirectTo={'/'}
              />
              <ConditionalRoute
                exact={true}
                path={'/pembayaran-penjualan/:id'}
                routeCondition={isLoggedIn}
                component={PaymentDetailPage}
                redirectTo={'/'}
              />
              <ConditionalRoute
                exact={true}
                path={'/pembayaran-pembelian'}
                routeCondition={isLoggedIn}
                component={PurchasePaymentPage}
                redirectTo={'/'}
              />
              <ConditionalRoute
                exact={true}
                path={'/pembayaran-pembelian/tambah'}
                routeCondition={isLoggedIn}
                component={PurchasePaymentCreatePage}
                redirectTo={'/'}
              />
              <ConditionalRoute
                exact={true}
                path={'/pembayaran-pembelian/:id'}
                routeCondition={isLoggedIn}
                component={PurchasePaymentDetailPage}
                redirectTo={'/'}
              />
              <ConditionalRoute
                exact={true}
                path={'/return-barang-rusak'}
                routeCondition={isLoggedIn}
                component={ProductDamagePage}
                redirectTo={'/'}
              />
              <ConditionalRoute exact={true} path={'/return-penjualan'} routeCondition={isLoggedIn} component={ReturnOrderPage} redirectTo={'/'} />
              <ConditionalRoute exact={true} path={'/return-pembelian'} routeCondition={isLoggedIn} component={ReturnOrderPage} redirectTo={'/'} />
              <ConditionalRoute
                exact={true}
                path={'/return-penjualan/detail'}
                routeCondition={isLoggedIn}
                component={ReturnDetailPage}
                redirectTo={'/'}
              />
              <ConditionalRoute
                exact={true}
                path={'/return-pembelian/detail'}
                routeCondition={isLoggedIn}
                component={ReturnDetailPage}
                redirectTo={'/'}
              />
              <ConditionalRoute
                exact={true}
                path={'/return-penjualan/:params'}
                routeCondition={isLoggedIn}
                component={ReturnOrderCreatePage}
                redirectTo={'/'}
              />
              <ConditionalRoute
                exact={true}
                path={'/return-pembelian/:params'}
                routeCondition={isLoggedIn}
                component={ReturnOrderCreatePage}
                redirectTo={'/'}
              />

              <ConditionalRoute
                exact={true}
                path={'/forgotpassword'}
                routeCondition={!isLoggedIn}
                component={ForgotPasswordPage}
                redirectTo={'/mitra'}
              />
              <ConditionalRoute exact={true} path={'/resetpassword'} routeCondition={!isLoggedIn} component={ResetPasswordPage} redirectTo={'/'} />
              <ConditionalRoute exact={true} path={'/appLog'} routeCondition={isLoggedIn} component={AppLogPage} redirectTo={'/'} />

              <ConditionalRoute exact={true} path={'/cek-stok'} routeCondition={isLoggedIn} component={StockCheckPage} redirectTo={'/'} />
              <ConditionalRoute exact={true} path={'/cek'} routeCondition={isLoggedIn} component={StockCheckCreatePage} redirectTo={'/'} />
              <ConditionalRoute exact={true} path={'/cek-detail'} routeCondition={isLoggedIn} component={StockCheckDetailPage} redirectTo={'/'} />
              <ConditionalRoute exact={true} path={'/keuangan'} routeCondition={isLoggedIn} component={FinancePage} redirectTo={'/'} />
              <ConditionalRoute exact={true} path={'/pergerakan-stok'} routeCondition={isLoggedIn} component={StockMovementPage} redirectTo={'/'} />

              <ConditionalRoute exact={true} path={'/review-stock'} routeCondition={isLoggedIn} component={ReviewStock} redirectTo={'/'} />
              <ConditionalRoute exact={true} path={'/review-stock/:id'} routeCondition={isLoggedIn} component={ReviewStockDetail} redirectTo={'/'} />
              <ConditionalRoute exact={true} path={'/review-stock/:id'} routeCondition={isLoggedIn} component={ReviewStockDetail} redirectTo={'/'} />
              <Route component={NotFoundPage} />
            </Switch>
          </main>
        </div>
      </CartProvider>
    </CurrentUserProvider>
  );
};

export default App;
