import React, { useState, useEffect, lazy, Suspense, useCallback } from 'react';
import { Container, Grid, makeStyles, Typography } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import axios from 'axios';
import { Breadcrumb, Page, PaperCustom } from 'components';
import FlexBox from 'components/FlexBox';
import { CATEGORY_BASE_URL, PRODUCT_SEARCH, STOCK_MOVEMENT_BASE_URL } from 'constants/url';
import useDebounce from 'hooks/useDebounce';
import { dummyMetaData } from 'utils/dummy';
import { StockMovementFilters, StockMovementHeader } from './components';
import PaginationCustom from 'components/PaginationCustom';
/* The above code is using lazy loading to load the component StockMovementContent. */
const StockMovementContent = lazy(() => import('./components/StockMovementContent'));

/**
 * TListExpand is an object with an id property that is a number or string, an isExpand property that
 * is a boolean, and an action property that is either 'products' or 'months'.
 * @property {number | string} id - The id of the item that is being expanded.
 * the list item is expanded or not.
 * @property {'products' | 'months'} action - 'products' | 'months';
 */
export type TListExpand = {
  id: number | string;
  action: 'products' | 'months';
};

/**
 * TFilters is an object with three properties: keyWord, productName, and categoryId.
 *
 * The keyWord property is a string.
 *
 * The productName property is a string.
 *
 * The categoryId property is a number.
 * @property {string} keyWord - string;
 * @property {string} productName - string;
 * @property {number} categoryId - number;
 */
export type TFilters = {
  keyWord: string;
  productName: string;
  categoryId: number;
  isProductPackage: boolean;
};

/**
 * `TData` is a generic type that takes a generic type `T` and returns an object with two properties:
 * `isLoading` and `data`.
 *
 * `isLoading` is a boolean and `data` is of type `T`.
 * @property {boolean} isLoading - boolean;
 * @property {T} data - The data that is returned from the API.
 */
export type TData<T> = {
  isLoading: boolean;
  data: T;
};

/* Defining a type called TFilters and then creating a constant called dummyFilters that is of type
TFilters. */
export const dummyFilters: TFilters = {
  productName: '',
  keyWord: '',
  isProductPackage: false,
  categoryId: 0
};

/* Creating a dummyData object with two properties: isLoading and data. */
const dummyData = {
  isLoading: true,
  data: []
};

const dummySummary: stockMovementSummary = {
  totalStockFirstStart: 0,
  totalStockDamageStart: 0,
  totalStockSalesStart: 0,
  totalStockEnd: 0,
  totalStockDamageEnd: 0,
  totalStockSalesEnd: 0
};

/* A function that returns a react component. */
const StockMovementPage = () => {
  /* Setting the initial state of the filters. */
  const [filters, setFilters] = useState<TFilters>(dummyFilters);
  /* Setting the initial state of the products to an object with isLoading set to true and data
  set to an empty array. */
  const [products, setProducts] = useState<TData<StockMovementProduct[]>>(dummyData);
  /* Declaring a variable called productMonths and setting it to dummyData. */
  const [productMonths, setProductMonths] = useState<TData<StockMovementProductMonth[]>>(dummyData);
  /* Declaring a variable called productSuggests and setting it to an empty array. */
  const [productSuggests, setProductSuggests] = useState<{ productName: string }[]>([]);
  /* Declaring a variable called productCategories and setting it to dummyData. */
  const [productCategories, setProductCategories] = useState<TData<CategoryModel[]>>(dummyData);
  /* It's setting the initial state of productMeta to an object with three properties: current_page,
  from, and last_page. */
  const [productMeta, setProductMeta] = useState<MetaData>(dummyMetaData);
  /* It's setting the initial state of isLoadingDownload to false. */
  const [isLoadingDownload, setIsLoadingDownload] = useState<boolean>(false);
  /* It's setting the initial state of stockMovements to an object with isLoading set to true and data
 set to an empty array. */
  const [stockMovements, setStockMovements] = useState<TData<StockMovement[]>>(dummyData);

  const [stockMovementSummary, setStockMovementSummary] = useState<TData<stockMovementSummary>>({
    isLoading: dummyData.isLoading,
    data: dummySummary
  });

  /* Setting the state of productExpand to an object with an id and isOpen property. */
  const [productExpand, setProductExpand] = useState<{ id: number; isOpen: boolean }>({
    id: 0,
    isOpen: false
  });
  /* Setting the state of monthExpand to an object with a month and isOpen property. */
  const [monthExpand, setMonthExpand] = useState<{ month: string; isOpen: boolean }>({
    month: '',
    isOpen: false
  });

  /* It's creating a cancel token. */
  const source = axios.CancelToken.source();
  const cancelToken = source.token;

  /**
   * `handleChangeFilters` is a function that takes a key of type `keyof typeof dummyFilters` and a value
   * of type `T` and returns a function that takes a value of type `T` and returns a function that takes
   * a value of type `T` and returns a function that takes a value of type `T` and returns a function
   * that takes a value of type `T` and returns a function that takes a value of type `T` and returns a
   * function that takes a value of type `T` and returns a function that takes a value of type `T` and
   * returns a function that takes a value of type `T` and returns a function that takes a value of type
   * `T` and returns a function that takes a value of type `T` and returns a function that takes a value
   * of type `T` and returns a function that takes a value of type `T` and returns a function that takes
   * a value of
   * @param key - keyof typeof dummyFilters
   * @param {T} value - T
   */
  const handleChangeFilters = <T,>(key: keyof typeof dummyFilters, value: T) => {
    setFilters(prev => ({ ...prev, [key]: value }));
  };

  /**
   * When a user clicks on a suggested product, the product name is added to the search bar, the
   * current page is set to 1, and the product suggestions are cleared.
   * @param {string} productName - string
   */
  const handleClickSuggest = (productName: string) => {
    handleChangeFilters('keyWord', productName);
    handleChangeFilters('productName', productName);
    handleChangeFilters('categoryId', 0);
    setProductMeta(prev => ({ ...prev, current_page: 1 }));
    setProductSuggests([]);
  };

  /**
   * When the user presses the enter key, the function prevents the default action, calls another
   * function, and then sets the state of two variables
   * @param event - React.KeyboardEvent<HTMLDivElement>
   */
  const handleSearchPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleChangeFilters('productName', filters.keyWord);
      handleChangeFilters('isProductPackage', 'false');
      setProductMeta(prev => ({ ...prev, current_page: 1 }));
      setProductSuggests([]);
    }
  };

  /**
   * When the user clicks the clear button, the handleClearKeyWord function is called, which calls the
   * handleChangeFilters function, which sets the keyWord filter to an empty string, which clears the
   * productSuggests array.
   */
  const handleClearKeyWord = () => {
    handleChangeFilters('keyWord', '');
    setProductSuggests([]);
  };

  /**
   * This function fetches product suggests from the server and sets the product suggests state.
   */
  const fetchProductSuggests = async () => {
    const params = new URLSearchParams();
    params.append('keyword', filters.keyWord);
    params.append('isProductPackage', 'false');
    try {
      const { data } = await axios.get(`${PRODUCT_SEARCH}?${params}`, {
        cancelToken
      });
      setProductSuggests(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * I'm going to set the productCategories state to isLoading: true, then I'm going to make a request
   * to the server, and if the request is successful, I'm going to set the productCategories state to
   * isLoading: false and data: data.data, otherwise I'm going to log the error.
   */
  const fetchProductCategory = async () => {
    setProductCategories(prev => ({ ...prev, isLoading: true }));
    const params = new URLSearchParams();

    try {
      const { data } = await axios.get(`${CATEGORY_BASE_URL}?${params}`, {
        cancelToken
      });
      setProductCategories(prev => ({ ...prev, isLoading: false, data: data.data }));
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * When the user clicks on a page number, set the current_page to the page number that was clicked on.
   * @param event - React.ChangeEvent<unknown>
   * @param {number} page - number - The page number to navigate to.
   */
  const handleChangePagination = (event: React.ChangeEvent<unknown>, page: number) => {
    setProductMeta(prev => ({ ...prev, current_page: page }));
  };

  /**
   * When the user clicks the refresh button, reset the filters to the default values, fetch the
   * products, and reset the current page to 1.
   */
  const handleRefresh = () => {
    setFilters(dummyFilters);
    fetchProducts();
    setProductMeta(prev => ({ ...prev, current_page: 1 }));
  };

  /**
  Todo 
  */
  const handleDownload = async () => {
    setIsLoadingDownload(true);
    try {
      setTimeout(() => setIsLoadingDownload(false), 3000);
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * The function fetches products from the server and sets the state of the products and productMeta.
   */
  const fetchProducts = async () => {
    setProducts(prev => ({ ...prev, isLoading: true }));
    const params = new URLSearchParams();

    params.append('page', productMeta.current_page.toString());
    if (filters.productName) {
      params.append('keyword', filters.productName);
      params.append('isProductPackage', 'false');
    }
    if (filters.categoryId !== 0) {
      params.append('CategoryId', filters.categoryId.toString());
    }

    try {
      const { data } = await axios.get(`${STOCK_MOVEMENT_BASE_URL}?${params}`, {
        cancelToken
      });
      setProducts({
        isLoading: false,
        data: data.data.map((product: StockMovementProduct) => {
          return { ...product, hasExpand: false };
        })
      });

      setProductMeta(prev => ({ ...prev, ...data.meta }));
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * FetchProductMonths is a function that takes an id as a parameter and returns a promise that
   * resolves to an object with a data property that is an array of objects with an isExpand property
   * that is a boolean.
   * @param {number} id - number - the id of the product
   */
  const fetchProductMonths = async (id: number) => {
    setProductMonths(prev => ({ ...prev, isLoading: true }));
    try {
      const { data } = await axios.get(`${STOCK_MOVEMENT_BASE_URL}/date/${id}`, {
        cancelToken
      });
      setProductMonths(prev => ({
        isLoading: false,
        data: data.data
      }));
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * "fetchStockMovement" is a function that fetches stock movement data from an API and sets the data
   * to the "stockMovements" state
   * @param {string} date - string =&gt; date is a string
   */
  const fetchStockMovement = async (date: string) => {
    setStockMovements(prev => ({ ...prev, isLoading: true }));

    const params = new URLSearchParams();

    params.append('date', date);
    params.append('productId', String(productExpand.id));

    try {
      const { data } = await axios.get(`${STOCK_MOVEMENT_BASE_URL}/detail?${params}`, {
        cancelToken
      });
      setStockMovements(prev => ({
        ...prev,
        isLoading: false,
        data: data.data
      }));
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * It fetches data from an API and if the data is not empty, it logs the data
   * @param {string} date - string =&gt; date is a string
   */
  const fetchStockMovementSummary = async (date: string) => {
    setStockMovementSummary(prev => ({ ...prev, isLoading: true, data: dummySummary }));
    const params = new URLSearchParams();
    params.append('date', date);
    params.append('productId', String(productExpand.id));
    try {
      const { data } = await axios.get(`${STOCK_MOVEMENT_BASE_URL}/display-total?${params}`, {
        cancelToken
      });
      if (data.length > 0) {
        const dataSummary: Readonly<Required<Record<keyof stockMovementSummary, number>>> = data[0];
        setStockMovementSummary(prev => ({ ...prev, isLoading: false, data: dataSummary }));
      }
    } catch (error) {
      setStockMovementSummary(prev => ({ ...prev, isLoading: false, data: dummySummary }));
    }
  };

  /* 1. It is fetching the products on the first render.
  2. It is fetching the products when the current page changes.
  3. It is fetching the products when the product name changes.
  4. It is cancelling the fetch when the component unmounts. */
  useEffect(() => {
    fetchProducts();
    return () => source.cancel('Cancel Unmounted');
  }, [productMeta.current_page, filters.productName, filters.categoryId]);

  /* Using the useDebounce hook to debounce the productNameDebouce variable. */
  const productNameDebouce = useDebounce(filters.keyWord, 500);

  /* A useEffect hook that is called when the productNameDebouce changes. */
  useEffect(() => {
    if (productNameDebouce.length < 1 || filters.productName === filters.keyWord) return;
    fetchProductSuggests();
    return () => source.cancel('Cancel Unmounted');
  }, [productNameDebouce]);

  /* A callback function that is being used to expand the list. */
  const handleListExpand = useCallback(
    ({ id, action }: TListExpand) => {
      if (action.includes('products')) {
        setProducts(prev => ({
          ...prev,
          data: prev.data.map(product => {
            return { ...product, hasExpand: product.id === id };
          })
        }));

        setProductExpand(prev => ({
          id: Number(id),
          isOpen: prev.id === id ? !prev.isOpen : true
        }));

        if (products.data.filter(product => product.id === id)[0].hasExpand) return;
        fetchProductMonths(+id);
      } else {
        setMonthExpand(prev => ({
          month: String(id),
          isOpen: prev.month === id ? !prev.isOpen : true
        }));

        const date = +id.toString().slice(5) < 10 ? `${new Date(id.toString()).getFullYear()}-0${id.toString().slice(5)}` : String(id);
        fetchStockMovementSummary(date);
        fetchStockMovement(date);
      }
    },
    [products, productMonths, stockMovements]
  );

  /* It's creating an object with three properties: isLoadingDownload, handleDownload, and
  handleRefresh. */
  const handleOnClickHeader = {
    isLoadingDownload,
    handleDownload,
    handleRefresh
  };

  /* Calling the fetchProductCategory() function when the component mounts. */
  useEffect(() => {
    fetchProductCategory();
  }, []);

  /* Returning a react component. */
  return (
    <Page title='Pergerakan Stok'>
      <Container>
        <Grid container direction='row'>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography variant='h1'>Pergerakan Stok</Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Breadcrumb />
          </Grid>
        </Grid>
        <PaperCustom>
          <FlexBox container rowGap={1}>
            <StockMovementHeader
              /* It's passing a react component to the titleNode prop of the StockMovementHeader
             component. */
              titleNode={
                <Typography variant='h6'>
                  Menampilkan {products.data.length} Produk ({productMeta.from || 0} - {productMeta.to || 0} dari {productMeta.total || 0})
                </Typography>
              }
              /* It's passing an object to the onClick prop of the StockMovementHeader component. */
              onClick={handleOnClickHeader}
            />
            {/*  It's passing the filters state and the handleChangeFilters function to the
            StockMovementFilters component.  */}
            <StockMovementFilters
              filters={filters}
              handleChangeFilters={handleChangeFilters}
              productSuggests={productSuggests}
              productCategories={productCategories}
              handleClickSuggest={handleClickSuggest}
              handleClearKeyWord={handleClearKeyWord}
              handleSearchPress={handleSearchPress}
            />
            {/* It's passing the products state, the stockMovements state, and the handleListExpand
            function to the StockMovementContent component. */}

            <Suspense
              fallback={
                <Grid xs={12} container justify='center' alignItems='center'>
                  <Typography variant='h6'>Sedang Membuka...</Typography>
                </Grid>
              }
            >
              <StockMovementContent
                products={products}
                stockMovements={stockMovements}
                stockMovementSummary={stockMovementSummary}
                handleListExpand={handleListExpand}
                productMonths={productMonths}
                productExpand={productExpand}
                monthExpand={monthExpand}
              />
            </Suspense>
            {/* It's a pagination component. */}
            <Grid xs={12} justify='flex-end' container>
              <PaginationCustom
                marginTop='-.4em '
                show={true}
                sxPagination={{
                  count: productMeta.last_page,
                  boundaryCount: 2,
                  variant: 'outlined',
                  shape: 'rounded',
                  onChange: (event, page) => {
                    setProductMeta(prev => ({ ...prev, current_page: page }));
                  },
                  page: productMeta.current_page
                }}
                sxPopover={{
                  anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                  },
                  transformOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                  }
                }}
                customPageProps={{
                  defaultValue: productMeta.current_page,
                  maxValue: productMeta.last_page,
                  onSubmit(value) {
                    setProductMeta(prev => ({ ...prev, current_page: value }));
                  }
                }}
              />
            </Grid>
          </FlexBox>
        </PaperCustom>
      </Container>
    </Page>
  );
};

/* Exporting the StockMovementPage function. */
export default StockMovementPage;
