const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const LOGIN_URL = `${BASE_URL}/login`;
export const LOGOUT_URL = `${BASE_URL}/logout`;
export const FORGOT_PASSWORD_URL = `${BASE_URL}/forgotpassword`;
export const RESET_PASSWORD_URL = `${BASE_URL}/resetpassword`;
export const CHANGE_PASSWORD_URL = `${BASE_URL}/changepassword`;

export const USER_BASE_URL = `${BASE_URL}/users`;
export const GET_CURRENT_USER_URL = `${USER_BASE_URL}/me`;
export const GET_EDIT_USER_URL = (userId: number) => `${USER_BASE_URL}/${userId}`;
export const GET_SALES_USER_URL = (params: string) => `${USER_BASE_URL}?${params}`;
export const GET_USER_DETAIL_URL = (id: number) => `${USER_BASE_URL}/${id}`;
export const GET_CONFIRMATION_PASSWORD_URL = `${USER_BASE_URL}/confirmation-password`;
export const USER_CHANGE_PASSWORD_BASE_URL = `${USER_BASE_URL}/change-password`;

export const COMPANY_BASE_URL = `${BASE_URL}/company`;
export const COMPANY_UPLOAD_BASE_URL = `${COMPANY_BASE_URL}/upload`;

export const ACCESS_USER_BASE_URL = `${BASE_URL}/access`;
export const GET_EDIT_ACCESS_USER_URL = (accesUserId: number) => `${ACCESS_USER_BASE_URL}/${accesUserId}`;

export const ROLE_BASE_URL = `${BASE_URL}/roles`;

export const PERMISSION_BASE_URL = `${BASE_URL}/permission`;
export const SET_PERMISSION_BASE_URL = `${BASE_URL}/permission/set-permission`;
export const DELETE_PERMISSION_BASE_URL = `${BASE_URL}/permission/delete-permission`;
export const GET_PERMISSION_BASE_URL = (userId: number) => `${BASE_URL}/permission/get-permission/${userId}`;

export const PARTNER_BASE_URL = `${BASE_URL}/partner`;
export const PARTNER_SEARCH_BASE_URL = `${PARTNER_BASE_URL}/search-partner`;
export const PARTNER_LAST_ID_BASE_URL = `${PARTNER_BASE_URL}/last`;
export const PARTNER_DETAIL_BASE_URL = (id: number) => `${PARTNER_BASE_URL}/${id}`;

export const WILAYAH_BASE_URL = `${BASE_URL}/wilayah`;
export const WILAYAH_PROVINSI_BASE_URL = `${WILAYAH_BASE_URL}/provinsi`;
export const WILAYAH_KABUPATEN_BASE_URL = (code: string) => `${WILAYAH_BASE_URL}/kabupaten/${code}`;
export const WILAYAH_KECAMATAN_BASE_URL = (code: string) => `${WILAYAH_BASE_URL}/kecamatan/${code}`;

export const ZONE_BASE_URL = `${BASE_URL}/zone`;

export const PRODUCT_BASE_URL = `${BASE_URL}/product`;
export const GET_CODE_BASE_URL = `${PRODUCT_BASE_URL}/get-code`;
export const PRODUCT_SEARCH = `${PRODUCT_BASE_URL}/search`;
export const PRODUCT_UPLOAD_MAGE_BASE_URL = `${PRODUCT_BASE_URL}/upload`;
export const GET_PRODUCT_DETAIL_BASE_URL = (id: number) => `${PRODUCT_BASE_URL}/${id}`;
export const PRODUCT_IMAGE_DELETE_BASE_URL = (id: number) => `${PRODUCT_BASE_URL}/image/${id}`;

export const PACKAGE_BASE_URL = `${BASE_URL}/package`;

export const STOCK_BASE_URL = `${BASE_URL}/stock`;
export const STOCK_ALL_BASE_URL = `${STOCK_BASE_URL}/all`;
export const SALES_ALL_EXPORT_BASE_URL = `${STOCK_ALL_BASE_URL}/export`;

export const STOCK_WAREHOUSE_BASE_URL = `${STOCK_BASE_URL}/warehouse`;
export const STOCK_UPDATE = `${STOCK_BASE_URL}/update`;
export const GET_STOCK_DETAIL_BASE_URL = (id: number) => `${STOCK_BASE_URL}/${id}`;
export const DELETE_STOCK_DETAIL_BASE_URL = (id: number) => `${STOCK_BASE_URL}/${id}`;
export const DETAIL_STOCK = (id: number) => `${STOCK_ALL_BASE_URL}/${id}`;

export const STOCK_CHECK_BASE_URL = `${BASE_URL}/stock-check`;
export const STOCK_CHECK_GET_ADJUSTMENT_BASE_URL = `${STOCK_CHECK_BASE_URL}/adjustment`;
export const EXPORT_STOCK_CHECK_BASE_URL = `${STOCK_CHECK_BASE_URL}/export`;
export const GET_STOCK_ACTIVE_BASE_URL = `${STOCK_CHECK_BASE_URL}/active-stock`;
export const GET_STOCK_CHECK_DETAIL_BASE_URL = (id: number) => `${STOCK_CHECK_BASE_URL}/${id}`;
export const DELETE_STOCK_CHECK_BASE_URL = (id: number) => `${STOCK_CHECK_BASE_URL}/${id}`;

export const STOCK_ITEM_BASE_URL = `${BASE_URL}/stock-item`;
export const GET_PRODUCT_WAREHOUSE_BASE_URL = (id: number) => `${STOCK_ITEM_BASE_URL}/product/${id}`;

export const CATEGORY_BASE_URL = `${BASE_URL}/category`;
export const WAREHOUSE_BASE_URL = `${BASE_URL}/warehouse`;
export const SALES_BASE_URL = `${BASE_URL}/sales`;

export const SALES_ORDER_BASE_URL = `${BASE_URL}/sales-order`;
export const GET_NOTIF_DISCOUNT = `${SALES_ORDER_BASE_URL}/notif`;
export const SALES_ORDER_EXPORT_BASE_URL = `${SALES_ORDER_BASE_URL}/export`;
export const SALES_ORDER_UPLOAD_BASE_URL = `${SALES_ORDER_BASE_URL}/upload`;
export const GET_SALES_ORDER_DETAIL_BASE_URL = (id: number) => `${SALES_ORDER_BASE_URL}/${id}`;
export const GET_SALES_ORDER_ITEM_BASE_URL = (id: number) => `${BASE_URL}/order-item/${id}`;
export const GET_SALES_ORDER_NUMBER_BASE_URL = `${SALES_ORDER_BASE_URL}/number`;
export const CHECK_SALES_ORDER_BASE_URL = `${SALES_ORDER_BASE_URL}/check`;
export const SET_ISOPEN_SO = (id: number) => `${SALES_ORDER_BASE_URL}/setisopen/${id}`;
export const SET_CONFIRM_SO = (id: number) => `${SALES_ORDER_BASE_URL}/setisaccept/${id}`;
export const SET_CONFIRM_PLAFON = (id: number) => `${SALES_ORDER_BASE_URL}/setisplafon/${id}`;
export const SET_ISCLOSE_SO = (id: number) => `${SALES_ORDER_BASE_URL}/unsetisopen/${id}`;
export const GET_HISTORY_DISCOUNT = (idPartner: number, idProduct: number) =>
  `${SALES_ORDER_BASE_URL}/lastdiscount?PartnerId=${idPartner}&ProductId=${idProduct}`;
export const GET_HISTORY_PRICE = (idPartner: number, idProduct: number) =>
  `${SALES_ORDER_BASE_URL}/lastprice?PartnerId=${idPartner}&ProductId=${idProduct}`;
export const DASHBOARD_BASE_URL = `${BASE_URL}/dashboard`;
export const STOCK_MOVEMENT_BASE_URL = `${BASE_URL}/stock-movement`;
export const ORDER_ITEM_BASE_URL = `${BASE_URL}/order-item`;
export const DELETE_SALES_ORDER_ITEM = (id: number) => `${ORDER_ITEM_BASE_URL}/${id}`;
export const CHANGE_STATUS_SALES_ORDER_ITEM = `${ORDER_ITEM_BASE_URL}/action`;
export const GET_HISTORY_LOG_ORDER_ITEM = (id: number) => `${ORDER_ITEM_BASE_URL}/history/${id}`;
export const CREATE_COLLY = `${ORDER_ITEM_BASE_URL}/bag`;
export const GET_COLLY_DETAILS = (id: number) => `${CREATE_COLLY}/?SalesOrderId=${id}`;

export const DELIVERY_ORDER_BASE_URL = `${BASE_URL}/delivery`;
export const DELIVERY_ORDER_DETAILS = (id: number) => `${DELIVERY_ORDER_BASE_URL}/${id}`;
export const DELIVERY_ORDER_PARTNER = `${DELIVERY_ORDER_BASE_URL}/partner`;
export const DELIVERY_ORDER_NOTIF = `${DELIVERY_ORDER_BASE_URL}/total-request-print`;
export const DELIVERY_ORDER_INVOICE = `${DELIVERY_ORDER_BASE_URL}/invoice`;
export const DELIVERY_ORDER_PRINT = (id: number) => `${DELIVERY_ORDER_BASE_URL}/bag-print/${id}`;
export const DELIVERY_ORDER_ALLOW_PRINT = (id: number) => `${DELIVERY_ORDER_BASE_URL}/update-print/${id}`;
export const DELIVERY_ORDER_REQUEST_PRINT = (id: number) => `${DELIVERY_ORDER_BASE_URL}/request-print/${id}`;

export const PURCHASE_ORDER_BASE_URL = `${BASE_URL}/purchase-order`;
export const PURCHASE_ORDER_UPLOAD_BASE_URL = `${PURCHASE_ORDER_BASE_URL}/upload`;
export const GET_PURCHASE_ORDER_DETAIL_BASE_URL = (id: number) => `${PURCHASE_ORDER_BASE_URL}/${id}`;
export const GET_PURCHASE_ORDER_ITEM_BASE_URL = (id: number) => `${PURCHASE_ORDER_BASE_URL}/item/${id}`;
export const GET_PURCHASE_ORDER_NUMBER_BASE_URL = `${PURCHASE_ORDER_BASE_URL}/number`;

export const PURCHASE_INVOICE_BASE_URL = `${BASE_URL}/purchase-invoice`;
export const PURCHASE_INVOICE_UPLOAD_BASE_URL = `${PURCHASE_INVOICE_BASE_URL}/upload`;
export const GET_PURCHASE_INVOICE_DETAIL_BASE_URL = (id: number) => `${PURCHASE_INVOICE_BASE_URL}/${id}`;
export const GET_PURCHASE_INVOICE_ITEMS_BASE_URL = (id: number) => `${PURCHASE_INVOICE_BASE_URL}/items/${id}`;
export const GET_PURCHASE_INVOICE_ITEM_BASE_URL = (id: number) => `${PURCHASE_INVOICE_BASE_URL}/item/${id}`;
export const GET_PURCHASE_INVOICE_NUMBER_BASE_URL = `${PURCHASE_INVOICE_BASE_URL}/invoice-number`;

export const INVOICE_BASE_URL = `${BASE_URL}/invoice`;
export const INVOICE_ROUNDING_BASE_URL = `${INVOICE_BASE_URL}/rounding`;
export const INVOICE_REQUEST_EDIT_BASE_URL = `${INVOICE_BASE_URL}/request-edit`;
export const INVOICE_CANCEL_BASE_URL = `${INVOICE_BASE_URL}/cancel`;
export const INVOICE_REQUEST_CANCEL_BASE_URL = `${INVOICE_BASE_URL}/request-cancel`;
export const EXPORT_INVOICE_BASE_URL = `${INVOICE_BASE_URL}/export`;
export const GET_INVOICE_PAYMENT_LIST_BASE_URL = (id: number) => `${INVOICE_BASE_URL}/payment/${id}`;
export const INVOICE_NEW_EDIT_BASE_URL = (id: number) => `${INVOICE_BASE_URL}/new-edit/${id}`;
export const GET_INVOICE_DETAIL_BASE_URL = (id: number) => `${INVOICE_BASE_URL}/${id}`;
export const INVOICE_CHECK = (partner: number, wareHouse: string) => `${INVOICE_BASE_URL}/checkInvoice?partner=${partner}&warehouse=${wareHouse}`;
export const INVOICE_COLLY = (invoiceId: string) => `${INVOICE_BASE_URL}/selectInvoice?invoiceid=${invoiceId}`;

export const COLLY_PREVIEW = (id: number) => `${INVOICE_BASE_URL}/bag-print/${id}?preview=true`;
export const INVOICE_ITEM_URL = `${BASE_URL}/invoice-item`;
export const INVOICE_ITEM_UPDATE_BASE_URL = `${INVOICE_ITEM_URL}/update`;
export const INVOICE_ITEM_LAST_PURCHASE_BASE_URL = `${INVOICE_ITEM_URL}/last-purchase`;
export const INVOICE_ITEM_DELETE_URL = (id: number) => `${INVOICE_ITEM_URL}/${id}`;

export const PURCHASE_INVOICE_ITEM_URL = `${BASE_URL}/purchase-item`;
export const INVOICE_PAYMENT_BASE_URL = `${BASE_URL}/invoice-payment`;
export const PURCHASE_PAYMENT_BASE_URL = `${BASE_URL}/purchase-invoice-payment`;
export const INVOICE_PAYMENT_GIRO_BASE_URL = `${INVOICE_PAYMENT_BASE_URL}/giro`;
export const EXPORT_INVOICE_PAYMENT_BASE_URL = `${INVOICE_PAYMENT_BASE_URL}/export`;
export const EXPORT_PURCHASE_PAYMENT_BASE_URL = `${PURCHASE_PAYMENT_BASE_URL}/export`;
export const GET_INVOICE_PAYMENT_DETAIL_BASE_URL = (id: number) => `${INVOICE_PAYMENT_BASE_URL}/${id}`;
export const GET_PURCHASE_INVOICE_PAYMENT_DETAIL_BASE_URL = (id: number) => `${PURCHASE_PAYMENT_BASE_URL}/${id}`;
export const GET_INVOICE_PAYMENT_RETURN_BASE_URL = (id: number) => `${INVOICE_PAYMENT_BASE_URL}/return/${id}`;
export const GET_INVOICE_PAYMENT_NUMBER_BASE_URL = `${INVOICE_PAYMENT_BASE_URL}/number`;

export const INVOICE_PIECE_BASE_URL = `${BASE_URL}/invoice-piece`;

export const COMMISSION_BASE_URL = `${BASE_URL}/commission`;
export const GET_COMMISSION_DETAIL_BASE_URL = (id: number) => `${COMMISSION_BASE_URL}/${id}`;
export const COMMISSION_EXPORT_BASE_URL = (id: number) => `${COMMISSION_BASE_URL}/export/${id}`;

export const RETURN_ORDER_BASE_URL = `${BASE_URL}/return-order`;
export const GET_RETURN_ORDER_DETAIL_BASE_URL = (id: number) => `${RETURN_ORDER_BASE_URL}/${id}`;

export const RETURN_ITEM_BASE_URL = `${BASE_URL}/return-item`;
export const RETURN_BILL_BASE_URL = `${BASE_URL}/return-bill`;

export const RETURN_INVOICE_BASE_URL = `${BASE_URL}/invoice-return`;
export const RETURN_DELETE_INVOICE_BASE_URL = (id: number) => `${RETURN_INVOICE_BASE_URL}/${id}`;
export const GET_RETURN_INVOICE_DETAIL_BASE_URL = (id: number) => `${RETURN_INVOICE_BASE_URL}/${id}`;
export const GET_RETURN_INVOICE_NOTIF_BASE_URL = (id: number) => `${RETURN_INVOICE_BASE_URL}/notif`;

export const SALES_ROUTE_BASE_URL = `${BASE_URL}/sales-route`;

export const BANK_BASE_URL = `${BASE_URL}/bank`;
export const GET_DETAIL_BANK_BASE_URL = (id: number) => `${BANK_BASE_URL}/${id}`;
export const DELETE_BANK_BASE_URL = (id: number) => `${BANK_BASE_URL}/${id}`;

export const MUTATION_BASE_URL = `${BASE_URL}/mutation`;
export const MUTATION_ACCEPTED_BASE_URL = `${MUTATION_BASE_URL}/accepted`;
export const GET_DETAIL_MUTATION_BASE_URL = (id: number) => `${MUTATION_BASE_URL}/${id}`;
export const DELETE_MUTATION_BASE_URL = (id: number) => `${MUTATION_BASE_URL}/${id}`;

export const APPLOG_BASE_URL = `${BASE_URL}/log-activity`;

export const BADGE_NOTIFICATION_BASE_URL = `${BASE_URL}/notification/badge`;
export const MAP_URL = (lat: number, lot: number) => `https://www.google.com/maps/?q=${lat},${lot}`;

export const ROLES_BASE_URL = `${BASE_URL}/roles`;

export const FINANCE_BASE_URL = `${BASE_URL}/finance`;
